import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-content" }
const _hoisted_3 = { class: "card-content" }
const _hoisted_4 = { class: "card-footer" }

import { publicClient } from '@api-sig/client-public';
import ApiError from '@api-sig/vue-api-error';
import { computed, reactive, ref } from 'vue';

import { useStore } from '@/store';


export default /*@__PURE__*/_defineComponent({
  ...{ name: 'Compte' },
  __name: 'Compte',
  setup(__props) {



const store = useStore();

const oldPassword = ref('');

const password = ref('');

const passwordConfirm = ref('');

const message = reactive({
  old_password: '',
  password: '',
  confirm: '',
  notif: '',
});

const variant = reactive({
  old_password: '',
  password: '',
  confirm: '',
  notif: '',
});

const user = computed(() => store.getters['auth/user']);

const changePassword = async () => {
  message.old_password = '';
  message.password = '';
  message.confirm = '';
  variant.old_password = '';
  variant.password = '';
  variant.confirm = '';

  if (oldPassword.value === '') {
    message.old_password = "l'ancien mot de passe ne peut être vide";
    variant.old_password = 'danger';
  }

  if (password.value === '') {
    message.password = 'le mot de passe ne peut être vide';
    variant.password = 'danger';
  }

  if (password.value !== passwordConfirm.value) {
    message.confirm = 'le mot de passe et la confirmation ne correspondent pas';

    variant.confirm = 'danger';
  }

  if (variant.old_password || variant.password || variant.confirm) {
    return;
  }

  const client = await publicClient;

  try {
    await client.authNewPassword(undefined, {
      old_password: oldPassword.value,
      password: password.value,
    });

    variant.notif = 'success';
    message.notif = 'Mot de passe modifié';

    await store.dispatch('auth/login', {
      email: user.value.email,
      password: password.value,
    });

    oldPassword.value = '';
    password.value = '';
    passwordConfirm.value = '';
  } catch (error) {
    variant.notif = 'danger';

    message.notif = new ApiError(error).message;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_OInput = _resolveComponent("OInput")!
  const _component_OField = _resolveComponent("OField")!
  const _component_ONotification = _resolveComponent("ONotification")!
  const _component_OButton = _resolveComponent("OButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "card-header" }, [
      _createElementVNode("h4", { class: "card-header-title" }, "Compte")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        class: "card",
        onSubmit: _withModifiers(changePassword, ["prevent"])
      }, [
        _cache[5] || (_cache[5] = _createElementVNode("header", { class: "card-header" }, [
          _createElementVNode("h5", { class: "card-header-title" }, "Modification du mot de passe")
        ], -1)),
        _createElementVNode("section", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("small", null, "La modification du mot de passe invalidera vos jetons d'authentification pour l'API.", -1)),
          _createVNode(_component_OField, {
            label: "Ancien Mot de passe",
            variant: variant.old_password,
            message: message.old_password
          }, {
            default: _withCtx(() => [
              _createVNode(_component_OInput, {
                modelValue: oldPassword.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((oldPassword).value = $event)),
                type: "password",
                expanded: "",
                "password-reveal": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["variant", "message"]),
          _createVNode(_component_OField, {
            label: "Mot de passe",
            variant: variant.password,
            message: message.password
          }, {
            default: _withCtx(() => [
              _createVNode(_component_OInput, {
                modelValue: password.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
                type: "password",
                expanded: "",
                "password-reveal": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["variant", "message"]),
          _createVNode(_component_OField, {
            label: "Confirmation",
            variant: variant.confirm,
            message: message.confirm
          }, {
            default: _withCtx(() => [
              _createVNode(_component_OInput, {
                modelValue: passwordConfirm.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((passwordConfirm).value = $event)),
                type: "password",
                expanded: "",
                "password-reveal": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["variant", "message"]),
          (message.notif)
            ? (_openBlock(), _createBlock(_component_ONotification, {
                key: 0,
                variant: variant.notif
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(message.notif), 1)
                ]),
                _: 1
              }, 8, ["variant"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("footer", _hoisted_4, [
          _createVNode(_component_OButton, {
            "native-type": "submit",
            variant: "primary"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Save changes")
            ])),
            _: 1
          })
        ])
      ], 32)
    ])
  ]))
}
}

})