<template>
  <OSteps v-model="step" has-navigation>
    <OStepItem :step="1" :value="1" label="Récapitulatif offre" clickable>
      <div v-if="offer.technical_data">
        <h1 class="title">Récapitulatif offre</h1>

        <p>
          Cette commande concerne l'adresse :
          <b>{{ offer.technical_data.afnor_address }}</b
          >, localisée aux coordonnées {{ $latitude(offer.technical_data?.latitude) }} /
          {{ $longitude(offer.technical_data?.longitude) }}.
        </p>

        <p>Cette commande comporte les informations suivantes :</p>

        <ul>
          <li>Libellé : {{ offer.offer.offer_name }}</li>

          <li>Engagement : {{ offer.offer.duration_commitment }} mois</li>

          <li>Setup : {{ offer.offer.nrc }}&nbsp;€</li>

          <li>Récurrent mensuel : {{ offer.offer.mrc }}&nbsp;€/mois</li>
        </ul>
      </div>

      <div v-else>
        <OLoading :active="!offer.technical_data" full-page>
          <OIcon pack="fas" icon="sync-alt" size="large" spin> </OIcon>
        </OLoading>
      </div>
    </OStepItem>

    <OStepItem :step="2" :value="2" label="Contacts" variant="danger">
      <div v-for="(item, idx) of order_contacts" :key="idx" class="columns">
        <div class="column is-3">
          <OField :label="item.label">
            <OSelect v-model="item.id" placeholder="Choisir un contact">
              <option></option>

              <option v-for="c of contacts" :key="c.id" :value="c.id">
                <template v-if="c.type === 'person'">
                  {{ c.firstname }} {{ c.lastname }}
                  <template v-if="c.company_name">({{ c.company_name }})</template>
                </template>

                <template v-else>
                  {{ c.company_name }}
                  <template v-if="c.firstname || c.lastname">({{ c.firstname }} {{ c.lastname }})</template>
                </template>
              </option>
            </OSelect>
          </OField>
        </div>

        <template v-if="item.contact">
          <ul class="column is-3">
            <li>
              Société : {{ item.contact.company_name }}
              <template v-if="item.contact.company_department">({{ item.contact.company_department }})</template>
            </li>

            <li v-if="item.contact.firstname || item.contact.lastname">
              Personne : {{ item.contact.firstname }}
              {{ item.contact.lastname }}
            </li>

            <li v-if="item.contact.company_department">Service : {{ item.contact.company_department }}</li>

            <li v-if="item.contact.company_siret">SIRET : {{ item.contact.company_siret }}</li>

            <li v-if="item.contact.company_vat">N° TVA : {{ item.contact.company_vat }}</li>
          </ul>

          <ul class="column is-3">
            <li>{{ item.contact.address_line1 }}</li>

            <li v-if="item.contact.address_line2">
              {{ item.contact.address_line2 }}
            </li>

            <li v-if="item.contact.address_line3">
              {{ item.contact.address_line3 }}
            </li>

            <li>
              {{ item.contact.postcode }} {{ item.contact.city }}
              {{ item.contact.country }}
            </li>
          </ul>

          <ul class="column is-3">
            <li>Représentant : {{ item.contact.representative_full_name }}</li>

            <li>
              <FontAwesomeIcon icon="flag" />
              {{ item.contact.representative_language }}
            </li>

            <li v-if="item.contact.representative_email">
              <a :href="'mailto:' + item.contact.representative_email">
                <FontAwesomeIcon icon="envelope" />
                {{ item.contact.representative_email }}
              </a>
            </li>

            <li v-if="item.contact.representative_mobile_phone">
              <a :href="'tel:' + item.contact.representative_mobile_phone">
                <FontAwesomeIcon icon="mobile" />
                {{ item.contact.representative_mobile_phone }}
              </a>
            </li>

            <li v-if="item.contact.representative_office_phone">
              <a :href="'tel:' + item.contact.representative_office_phone">
                <FontAwesomeIcon icon="phone" />
                {{ item.contact.representative_office_phone }}
              </a>
            </li>

            <li v-if="item.contact.representative_role">
              {{ item.contact.representative_role }}
            </li>
          </ul>
        </template>
      </div>

      <OButton v-if="!showAddContact" @click.prevent="showAddContact = !showAddContact">Ajouter un contact</OButton>

      <form v-if="showAddContact" @submit.prevent="addContact">
        <div class="columns">
          <div class="column is-4">
            <OField
              label="Type de contact"
              :variant="variant(contactv$.type)"
              :message="message(contactv$.type)"
              horizontal
            >
              <OSelect v-model="contactv$.type.$model">
                <option value="company">Société</option>

                <option value="person">Personne</option>
              </OSelect>
            </OField>

            <OField
              label="Société"
              :variant="variant(contactv$.company_name)"
              :message="message(contactv$.company_name)"
              horizontal
            >
              <OInput v-model="contactv$.company_name.$model" />
            </OField>

            <OField
              label="Prénom"
              :variant="variant(contactv$.firstname)"
              :message="message(contactv$.firstname)"
              horizontal
            >
              <OInput v-model="contactv$.firstname.$model" />
            </OField>

            <OField
              label="Nom"
              :variant="variant(contactv$.lastname)"
              :message="message(contactv$.lastname)"
              horizontal
            >
              <OInput v-model="contactv$.lastname.$model" />
            </OField>

            <OField
              label="Service de la société"
              :variant="variant(contactv$.company_department)"
              :message="message(contactv$.company_department)"
              horizontal
            >
              <OInput v-model="contactv$.company_department.$model" />
            </OField>

            <OField
              label="SIRET"
              :variant="variant(contactv$.company_siret)"
              :message="message(contactv$.company_siret)"
              horizontal
            >
              <OInput v-model="contactv$.company_siret.$model" />
            </OField>

            <OField
              label="TVA"
              :variant="variant(contactv$.company_vat)"
              :message="message(contactv$.company_vat)"
              horizontal
            >
              <OInput v-model="contactv$.company_vat.$model" />
            </OField>
          </div>

          <div class="column is-4">
            <OField
              label="Adresse"
              :variant="variant(contactv$.address_line1)"
              :message="message(contactv$.address_line1)"
              horizontal
            >
              <OInput v-model="address_line" type="textarea" rows="3" />
            </OField>

            <OField
              label="Code postal"
              :variant="variant(contactv$.postcode)"
              :message="message(contactv$.postcode)"
              horizontal
            >
              <OInput v-model="contactv$.postcode.$model" />
            </OField>

            <OField label="Ville" :variant="variant(contactv$.city)" :message="message(contactv$.city)" horizontal>
              <OInput v-model="contactv$.city.$model" />
            </OField>

            <OField label="Pays" :variant="variant(contactv$.country)" :message="message(contactv$.country)" horizontal>
              <OInput v-model="contactv$.country.$model" />
            </OField>
          </div>

          <div class="column is-4">
            <OField
              label="Nom représentant"
              :variant="variant(contactv$.representative_full_name)"
              :message="message(contactv$.representative_full_name)"
              horizontal
            >
              <OInput v-model="contactv$.representative_full_name.$model" />
            </OField>

            <OField
              label="Langue"
              :variant="variant(contactv$.representative_language)"
              :message="message(contactv$.representative_language)"
              horizontal
            >
              <OSelect v-model="contactv$.representative_language.$model">
                <option value="fr">Français</option>

                <option value="en">English</option>
              </OSelect>
            </OField>

            <OField
              label="e-mail"
              :variant="variant(contactv$.representative_email)"
              :message="message(contactv$.representative_email)"
              horizontal
            >
              <OInput v-model="contactv$.representative_email.$model" />
            </OField>

            <OField
              label="Portable"
              :variant="variant(contactv$.representative_mobile_phone)"
              :message="message(contactv$.representative_mobile_phone)"
              horizontal
            >
              <OInput v-model="contactv$.representative_mobile_phone.$model" />
            </OField>

            <OField
              label="Tel fixe"
              :variant="variant(contactv$.representative_office_phone)"
              :message="message(contactv$.representative_office_phone)"
              horizontal
            >
              <OInput v-model="contactv$.representative_office_phone.$model" />
            </OField>

            <OField
              label="Fonction"
              :variant="variant(contactv$.representative_role)"
              :message="message(contactv$.representative_role)"
              horizontal
            >
              <OInput v-model="contactv$.representative_role.$model" />
            </OField>
          </div>
        </div>

        <div class="columns">
          <div class="column is-4">
            <OField horizontal>
              <OButton variant="secondary" @click="showAddContact = !showAddContact">Annuler</OButton>

              <OButton native-type="submit" variant="primary"> Créer le contact </OButton>
            </OField>
          </div>

          <div v-if="contactError" class="column is-4">
            {{ contactError }}
          </div>
        </div>
      </form>
    </OStepItem>

    <OStepItem :step="3" :value="3" label="Informations générales">
      <OField
        label="Nom du client final"
        :variant="variant(orderv$.customer_name)"
        :message="message(orderv$.customer_name)"
        horizontal
      >
        <OInput v-model="orderv$.customer_name.$model" />
      </OField>

      <OField
        label="Configuration PE"
        :variant="variant(orderv$.pe_configuration)"
        :message="message(orderv$.pe_configuration)"
        horizontal
      >
        <OSelect v-model="orderv$.pe_configuration.$model">
          <option value="router">Routeur</option>

          <option value="bridge">Bridge</option>
        </OSelect>
      </OField>

      <OField
        label="Informations complémentaires"
        :variant="variant(orderv$.extra_information)"
        :message="message(orderv$.extra_information)"
        horizontal
      >
        <OInput v-model="orderv$.extra_information.$model" type="textarea" />
      </OField>

      <OField
        label="Porte de collecte"
        :variant="variant(orderv$.bitstream_trunk)"
        :message="message(orderv$.bitstream_trunk)"
        horizontal
      >
        <OInput v-model="orderv$.bitstream_trunk.$model" />
      </OField>

      <OField
        label="Disponibilité sur site"
        :variant="variant(orderv$.premises_availability_date)"
        :message="message(orderv$.premises_availability_date)"
        horizontal
      >
        <ODatepicker
          v-model="orderv$.premises_availability_date.$model"
          :min-date="minDate"
          locale="fr-FR"
          icon="calendar"
        />
      </OField>
    </OStepItem>

    <OStepItem :step="4" :value="4" label="Récapitulatif Commande">
      <h1 class="title">Récapitulatif Commande</h1>

      <div class="columns">
        <div v-if="offer.technical_data" class="column is-4">
          <h3>Offre</h3>

          <p>
            Cette commande concerne l'adresse :
            <b>{{ offer.technical_data.afnor_address }}</b
            >, localisée aux coordonnées {{ $latitude(offer.technical_data?.latitude) }} /
            {{ $longitude(offer.technical_data?.longitude) }}.
          </p>

          <p>Cette commande comporte les informations suivantes :</p>

          <ul>
            <li>Libellé : {{ offer.offer.offer_name }}</li>

            <li>Engagement : {{ offer.offer.duration_commitment }} mois</li>

            <li>Setup : {{ offer.offer.nrc }}&nbsp;€</li>

            <li>Récurrent mensuel : {{ offer.offer.mrc }}&nbsp;€/mois</li>
          </ul>
        </div>

        <div class="column is-4">
          <h3>Contacts</h3>

          <dl>
            <template v-for="(item, idx) of order_contacts" :key="idx">
              <template v-if="item.contact">
                <dt>{{ item.label }}</dt>

                <dd>
                  <template v-if="item.contact.type === 'person'">
                    {{ item.contact.firstname }} {{ item.contact.lastname }}
                    <template v-if="item.contact.company_name"> ({{ item.contact.company_name }}) </template>
                  </template>

                  <template v-else>
                    {{ item.contact.company_name }}
                    <template v-if="item.contact.firstname || item.contact.lastname">
                      ({{ item.contact.firstname }} {{ item.contact.lastname }})
                    </template>
                  </template>

                  <br />

                  {{ item.contact.representative_full_name }}<br />
                  {{ item.contact.address_line1 }},
                  <template v-if="item.contact.address_line2"> {{ item.contact.address_line2 }}, </template>

                  <template v-if="item.contact.address_line3"> {{ item.contact.address_line3 }}, </template>

                  {{ item.contact.postcode }} {{ item.contact.city }} {{ item.contact.country }}<br />

                  <template v-if="item.contact.representative_email">
                    <a :href="'mailto:' + item.contact.representative_email">
                      <FontAwesomeIcon icon="envelope" />
                      {{ item.contact.representative_email }}
                    </a>

                    <br />
                  </template>

                  <template v-if="item.contact.representative_mobile_phone">
                    <a :href="'tel:' + item.contact.representative_mobile_phone">
                      <FontAwesomeIcon icon="mobile" />
                      {{ item.contact.representative_mobile_phone }}
                    </a>
                  </template>

                  <template v-if="item.contact.representative_office_phone">
                    <a :href="'tel:' + item.contact.representative_office_phone">
                      <FontAwesomeIcon icon="phone" />
                      {{ item.contact.representative_office_phone }}
                    </a>
                  </template>

                  <br />
                </dd>
              </template>
            </template>
          </dl>
        </div>

        <div class="column is-4">
          <h3>Informations complémentaires</h3>

          <dl>
            <dt>Nom du client</dt>

            <dd>{{ newOrder.customer_name }}</dd>

            <template v-if="newOrder.pe_configuration">
              <dt>Configuration PE</dt>

              <dd>{{ newOrder.pe_configuration }}</dd>
            </template>

            <template v-if="newOrder.extra_information">
              <dt>Informations complémentaires</dt>

              <dd>{{ newOrder.extra_information }}</dd>
            </template>

            <template v-if="newOrder.bitstream_trunk">
              <dt>Porte de collecte</dt>

              <dd>{{ newOrder.bitstream_trunk }}</dd>
            </template>

            <template v-if="newOrder.premises_availability_date">
              <dt>Disponibilité sur site</dt>

              <dd>
                {{
                  typeof newOrder.premises_availability_date === 'string'
                    ? newOrder.premises_availability_date
                    : (newOrder.premises_availability_date as unknown as Date).toISOString().slice(0, 10)
                }}
              </dd>
            </template>
          </dl>
        </div>
      </div>

      <OField horizontal>
        <OButton variant="primary" icon-left="check" @click.prevent="submitOrder"> Créer la commande </OButton>
        {{ orderError }}
      </OField>
    </OStepItem>

    <template #navigation="{ previous, next }">
      <OButton
        outlined
        variant="primary"
        icon-right="backward"
        :disabled="previous.disabled"
        @click.prevent="previous.action"
      >
        Revenir
      </OButton>

      <OButton
        outlined
        :variant="nextStepDisabled ? 'warning' : 'success'"
        icon-left="forward"
        :disabled="next.disabled || nextStepDisabled"
        @click.prevent="next.action"
      >
        Étape suivante
      </OButton>

      <p v-if="errorMessage" class="help is-danger">
        {{ errorMessage }}
      </p>
    </template>
  </OSteps>
</template>

<script lang="ts">
import { publicClient } from '@api-sig/client-public';
import type { Components } from '@api-sig/openapi-public';
import useVuelidate from '@vuelidate/core';
import { email, helpers, required, requiredIf, requiredUnless } from '@vuelidate/validators';
import type { Ref } from 'vue';
import { computed, defineComponent, nextTick, onMounted, reactive, ref, watch } from 'vue';
import type { Router } from 'vue-router';
import { useRouter } from 'vue-router';

import { message, variant } from '@/plugins/vuelidate';

const offerFunctions = (properties: { offerId: string }) => {
  const offer = ref<Components.Schemas.FullOffer>({} as Components.Schemas.FullOffer);

  const minDate = ref(new Date());

  const getOffer = async () => {
    const client = await publicClient;

    const resp = await client.offerList({ uniqid: properties.offerId });

    offer.value = resp.data;

    const today = new Date();

    /*
     * La date avant laquelle l'installation n'est pas possible
     * On ajoute 7 jours au cas où.
     */
    minDate.value = new Date(
      today.getFullYear(),
      today.getMonth() + offer.value.offer.estimated_commissioning_delay,
      today.getDate() + 7
    );
  };

  onMounted(getOffer);

  return { offer, minDate };
};

const contactsFunctions = () => {
  const contactError = ref('');
  const showAddContact = ref(false);
  const contacts = ref<Components.Schemas.Contact[]>([]);
  // FIXME: Le Required ne devrait pas être présent ici
  const newContact = reactive<Required<Components.Schemas.ContactCreate>>({
    address_line1: '',
    address_line2: '',
    address_line3: '',
    city: '',
    company_department: '',
    company_name: '',
    company_siret: '',
    company_vat: '',
    country: 'France',
    firstname: '',
    lastname: '',
    postcode: '',
    representative_email: '',
    representative_full_name: '',
    representative_language: 'fr',
    representative_mobile_phone: '',
    representative_office_phone: '',
    representative_role: '',
    type: 'company',
  });
  const addressLine = ref('');

  const rules = {
    address_line1: { required },
    address_line2: {},
    address_line3: {},
    city: { required },
    company_department: {},
    company_name: { required: requiredIf(() => newContact.type === 'company') },
    company_siret: {},
    company_vat: {},
    country: { required },
    firstname: { required: requiredIf(() => newContact.type === 'person') },
    lastname: { required: requiredIf(() => newContact.type === 'person') },
    postcode: { required },
    representative_email: { required, email },
    representative_full_name: { required },
    representative_language: { required },

    representative_mobile_phone: {
      required: helpers.withMessage(
        'Value is required, or the Office phone is',
        requiredUnless(() => !!newContact.representative_office_phone)
      ),
    },

    representative_office_phone: {
      required: helpers.withMessage(
        'Value is required, or the Mobile phone is',
        requiredUnless(() => !!newContact.representative_mobile_phone)
      ),
    },

    representative_role: {},
    type: { required },
  };

  const contactv$ = useVuelidate(rules, newContact);

  const getContacts = async () => {
    const client = await publicClient;

    const resp = await client.contactList();

    contacts.value.splice(0, contacts.value.length, ...resp.data);
  };

  const addContact = async () => {
    const client = await publicClient;

    contactv$.value.$touch();
    contactError.value = '';

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    nextTick(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      nextTick(async () => {
        if (contactv$.value.$error) {
          return;
        }

        try {
          const newContactCopy = { ...newContact };

          // Virer les entrées vides avant d'envoyer la requête
          for (const k of Object.keys(newContactCopy) as Array<keyof typeof newContactCopy>) {
            if (!newContactCopy[k]) {
              delete newContactCopy[k];
            }
          }

          const resp = await client.contactCreate(undefined, newContactCopy);

          if (resp.data.data) {
            contacts.value.push(resp.data.data);

            // On remet le bouton et on cache le forumlaire
            showAddContact.value = false;

            // On clean l'objet au cas où il faut créer un autre contact
            for (const k of Object.keys(newContact) as Array<keyof typeof newContact>) {
              if (k !== 'type' && k !== 'representative_language') {
                newContact[k] = '';
              }
            }
          }
        } catch (error) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          contactError.value = error instanceof Error ? error.message : `unknown error: ${error}`;
        }
      });
    });
  };

  onMounted(getContacts);

  watch(addressLine, (newValue: string) => {
    const splitted = newValue.split(/\n/);
    contactv$.value.address_line1.$model = splitted[0] ?? '';
    contactv$.value.address_line2.$model = splitted[1] ?? '';
    contactv$.value.address_line3.$model = splitted[2] ?? '';
  });

  return {
    contacts,
    getContacts,
    addContact,
    showAddContact,
    contactError,
    newContact,
    address_line: addressLine,
    contactv$,
  };
};

interface OrderContact {
  id: string;
  label: string;
  contact: Components.Schemas.Contact | undefined;
}

const orderFunctions = (properties: { offerId: string }, router: Router, cts: Ref<Components.Schemas.Contact[]>) => {
  const orderError = ref('');
  const orderContacts = [
    {
      id: '',
      label: 'Contact sur site',
      contact: undefined,
    },
    {
      id: '',
      label: 'Contact technique',
      contact: undefined,
    },
    {
      id: '',
      label: 'Contact facturation (optionel)',
      contact: undefined,
    },
  ].map((contactType) => reactive<OrderContact>(contactType));

  // Dans le formulaire, la date est au format texte.
  type NewOrder = Pick<
    Components.Schemas.OrderCreate,
    'bitstream_trunk' | 'extra_information' | 'offers_uniqids' | 'customer_name' | 'pe_configuration' | 'webhook_url'
  > & {
    premises_availability_date: string;
  };

  // FIXME: Le Required ne devrait pas être présent ici
  const newOrder = reactive<Required<NewOrder>>({
    bitstream_trunk: '',
    extra_information: '',
    offers_uniqids: properties.offerId,
    customer_name: '',
    pe_configuration: 'router',
    premises_availability_date: '',
    webhook_url: '',
  });

  const rules = {
    bitstream_trunk: {},
    extra_information: {},
    offers_uniqids: { required },
    customer_name: { required },
    pe_configuration: {},
    premises_availability_date: {},
    webhook_url: {},
  };

  const orderv$ = useVuelidate(rules, newOrder);

  const submitOrder = async () => {
    const client = await publicClient;

    const orderSubmit: Components.Schemas.OrderCreate = {
      ...newOrder,
      installation_site_contact_id: orderContacts[0].id,
      technical_contact_id: orderContacts[1].id,
      billing_contact_id: orderContacts[2].id,

      premises_availability_date: new Date(newOrder.premises_availability_date).valueOf(),
    };

    // Virer les entrées vides avant d'envoyer la requête
    for (const k of Object.keys(orderSubmit) as Array<keyof Components.Schemas.OrderCreate>) {
      if (orderSubmit[k] === undefined) {
        delete orderSubmit[k];
      }
    }

    let orderId = '';

    try {
      const resp = await client.orderCreate(undefined, orderSubmit);

      if (resp.data.data) {
        orderId = resp.data.data.id;
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      orderError.value = error instanceof Error ? error.message : `unknown error: ${error}`;
    }

    if (orderId) {
      await router.push({
        name: 'Commande',
        query: { orderId },
      });
    }
  };

  // On watch l'array
  watch(
    orderContacts,
    (newValue) => {
      for (const v of newValue) {
        if (v.id !== v.contact?.id) {
          v.contact = cts.value.find((c) => c.id === v.id);
        }
      }
    },
    { deep: true }
  );

  return {
    order_contacts: orderContacts,
    newOrder,
    orderv$,
    submitOrder,
    orderError,
  };
};

export default defineComponent({
  name: 'NewCommande',

  props: {
    offerId: {
      type: String,
      required: true,
    },
  },

  // eslint-disable-next-line vue/component-api-style
  setup(properties) {
    const router = useRouter();
    const step = ref<number>(1);

    const contactFns = contactsFunctions();
    const orderFns = orderFunctions(properties, router, contactFns.contacts);

    const errorMessage = computed(() => {
      switch (step.value) {
        case 1: {
          return '';
        }

        case 2: {
          if (!orderFns.order_contacts[0].id || !orderFns.order_contacts[1].id) {
            return 'Les contacts sur site et techniques doivent être renseignés';
          }

          return '';
        }

        case 3: {
          orderFns.orderv$.value.$touch();

          if (orderFns.orderv$.value.customer_name.$error) {
            return 'Le nom du client doit être renseigné';
          }

          return '';
        }

        case 4: {
          return '';
        }

        default: {
          return `Step ${step.value} non géré`;
        }
      }
    });

    const nextStepDisabled = computed(() => !!errorMessage.value);

    return {
      variant,
      message,
      nextStepDisabled,
      errorMessage,
      step,
      ...offerFunctions(properties),
      ...contactFns,
      ...orderFns,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep(textarea) {
  min-height: 5em;
}

dt {
  font-weight: bold;
}
</style>
