<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { useStore } from '@/store';

defineOptions({ name: 'Logout' });

const store = useStore();
const router = useRouter();

onMounted(async () => {
  await store.dispatch('auth/logout');
  await router.push({ name: 'Login' });
});
</script>
