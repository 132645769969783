import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }

import { computed } from 'vue';

import NavBar from './components/NavBar.vue';
import { useStore } from './store';


export default /*@__PURE__*/_defineComponent({
  ...{ name: 'App' },
  __name: 'App',
  setup(__props) {



const store = useStore();

const loggedIn = computed(() => store.getters['auth/loggedIn']);

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (loggedIn.value)
      ? (_openBlock(), _createBlock(NavBar, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_RouterView)
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("footer", { class: "footer" }, [
      _createTextVNode(" En cas de problèmes, merci d'envoyer un email à "),
      _createElementVNode("a", { href: "mailto:support@ovea.com?subject=Problème%20API%20d'éligibilité" }, "support@ovea.com")
    ], -1))
  ], 64))
}
}

})