<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <span class="navbar-item" :title="`v${version}`">
        <img src="@/assets/logo_ovea.png" class="logo" />

        <h5>Éligibilité</h5>
      </span>

      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navBarMenu">
        <span aria-hidden="true"></span>

        <span aria-hidden="true"></span>

        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navBarMenu" class="navbar-menu">
      <div class="navbar-start">
        <RouterLink class="navbar-item" :to="{ name: 'Recherche' }">Requêtes</RouterLink>

        <RouterLink :to="{ name: 'Historique' }" class="navbar-item">Historique Requêtes</RouterLink>
      </div>

      <div class="navbar-end">
        <a v-if="newVersion" class="has-background-warning navbar-item" @click="reloadConfirm">Nouvelle version</a>

        <RouterLink :to="{ name: 'Compte' }" class="navbar-item">Compte</RouterLink>

        <RouterLink :to="{ name: 'Logout' }" class="navbar-item">Logout</RouterLink>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { useStore } from '@/store';

defineOptions({ name: 'NavBar' });

const store = useStore();

const reloadConfirm = () => {
  // TODO: utiliser une fenêtre modale
  // eslint-disable-next-line no-alert, no-restricted-globals
  if (confirm("Une nouvelle version de l'application est disponible.\nRecharger la page ?")) {
    globalThis.location.reload();
  }
};

const version = computed(() => store.getters['version/version']);
const newVersion = computed(() => store.getters['version/newVersion']);
</script>

<style lang="scss" scoped>
h5 {
  font-size: 1.125em;
}
.logo {
  width: 4rem;
  margin-right: 0.4rem;
}
</style>
