import axios from 'axios';

let oldSource: string | undefined;

const isNewerVersionAvailable = async () => {
  // On peut utiliser as string car c'est toujours défini
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const returnValue = await axios.get<string>(process.env.BASE_URL as string);

  const freshSource = returnValue.data;

  if (oldSource === undefined) {
    oldSource = freshSource;
    return false;
  }

  if (oldSource === freshSource) {
    return false;
  }

  oldSource = freshSource;
  return true;
};

export default isNewerVersionAvailable;
