import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-content" }
const _hoisted_2 = { class: "card-footer" }

import { publicClient } from '@api-sig/client-public';
import ApiError from '@api-sig/vue-api-error';
import { nextTick, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  ...{ name: 'PasswordReset' },
  __name: 'PasswordReset',
  props: {
    token: { default: '' }
  },
  setup(__props: any) {


const props = __props;

const router = useRouter();

const password = ref('');

const passwordConfirm = ref('');

const message = reactive({ password: '', confirm: '', notif: '' });

const variant = reactive({ password: '', confirm: '', notif: '' });

const resetPassword = async () => {
  message.password = '';
  message.confirm = '';
  variant.password = '';
  variant.confirm = '';

  if (password.value === '') {
    message.password = 'le mot de passe ne peut être vide';
    variant.password = 'danger';
    return;
  }

  if (password.value !== passwordConfirm.value) {
    message.confirm = 'le mot de passe et la confirmation ne correspondent pas';

    variant.confirm = 'danger';
    return;
  }

  const client = await publicClient;

  try {
    await client.authRecoverPassword(undefined, {
      token: props.token,
      password: password.value,
    });

    variant.notif = 'success';
    message.notif = 'Mot de passe remis à zéro';

    await nextTick();

    await router.push({ name: 'Login' });
  } catch (error) {
    variant.notif = 'danger';

    message.notif = new ApiError(error).message;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_OInput = _resolveComponent("OInput")!
  const _component_OField = _resolveComponent("OField")!
  const _component_ONotification = _resolveComponent("ONotification")!
  const _component_OButton = _resolveComponent("OButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: "card",
    onSubmit: _withModifiers(resetPassword, ["prevent"])
  }, [
    _cache[3] || (_cache[3] = _createElementVNode("header", { class: "card-header" }, [
      _createElementVNode("h5", { class: "card-header-title" }, "Réinitialisation du mot de passe")
    ], -1)),
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_OField, {
        label: "Mot de passe",
        variant: variant.password,
        message: message.password
      }, {
        default: _withCtx(() => [
          _createVNode(_component_OInput, {
            modelValue: password.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((password).value = $event)),
            type: "password",
            expanded: "",
            "password-reveal": ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["variant", "message"]),
      _createVNode(_component_OField, {
        label: "Confirmation",
        variant: variant.confirm,
        message: message.confirm
      }, {
        default: _withCtx(() => [
          _createVNode(_component_OInput, {
            modelValue: passwordConfirm.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((passwordConfirm).value = $event)),
            type: "password",
            expanded: "",
            "password-reveal": ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["variant", "message"]),
      (message.notif)
        ? (_openBlock(), _createBlock(_component_ONotification, {
            key: 0,
            variant: variant.notif
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(message.notif), 1)
            ]),
            _: 1
          }, 8, ["variant"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("footer", _hoisted_2, [
      _createVNode(_component_OButton, {
        "native-type": "submit",
        variant: "primary"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Réinitialiser le mot de passe ")
        ])),
        _: 1
      })
    ])
  ], 32))
}
}

})