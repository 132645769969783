<template>
  <form class="card" @submit.prevent="resetPassword">
    <header class="card-header">
      <h5 class="card-header-title">Réinitialisation du mot de passe</h5>
    </header>

    <section class="card-content">
      <OField label="Mot de passe" :variant="variant.password" :message="message.password">
        <OInput v-model="password" type="password" expanded data-test="password" password-reveal />
      </OField>

      <OField label="Confirmation" :variant="variant.confirm" :message="message.confirm">
        <OInput v-model="passwordConfirm" type="password" expanded data-test="password-confirm" password-reveal />
      </OField>

      <ONotification v-if="message.notif" :variant="variant.notif">
        {{ message.notif }}
      </ONotification>
    </section>

    <footer class="card-footer">
      <OButton native-type="submit" variant="primary" data-test="submit"> Réinitialiser le mot de passe </OButton>
    </footer>
  </form>
</template>

<script setup lang="ts">
import { publicClient } from '@api-sig/client-public';
import ApiError from '@api-sig/vue-api-error';
import { nextTick, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

defineOptions({ name: 'PasswordReset' });
const props = withDefaults(defineProps<{ token?: string }>(), { token: '' });

const router = useRouter();

const password = ref('');

const passwordConfirm = ref('');

const message = reactive({ password: '', confirm: '', notif: '' });

const variant = reactive({ password: '', confirm: '', notif: '' });

const resetPassword = async () => {
  message.password = '';
  message.confirm = '';
  variant.password = '';
  variant.confirm = '';

  if (password.value === '') {
    message.password = 'le mot de passe ne peut être vide';
    variant.password = 'danger';
    return;
  }

  if (password.value !== passwordConfirm.value) {
    message.confirm = 'le mot de passe et la confirmation ne correspondent pas';

    variant.confirm = 'danger';
    return;
  }

  const client = await publicClient;

  try {
    await client.authRecoverPassword(undefined, {
      token: props.token,
      password: password.value,
    });

    variant.notif = 'success';
    message.notif = 'Mot de passe remis à zéro';

    await nextTick();

    await router.push({ name: 'Login' });
  } catch (error) {
    variant.notif = 'danger';

    message.notif = new ApiError(error).message;
  }
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 5%;
  justify-self: center;
  width: 400px;
}
.card-footer {
  padding: 1.5rem;
}
.notification {
  margin-top: 1em;
  padding: 0.8em 1em;
}
</style>
