import { AuthService } from '@api-sig/client-public';
import * as auth from '@api-sig/vuex-store-auth';
import * as version from '@api-sig/vuex-store-version';
import type { InjectionKey } from 'vue';
import type { Store as BaseStore } from 'vuex';
import { createLogger, createStore, useStore as baseUseStore } from 'vuex';

export interface State {
  auth: auth.State;
  version: version.State;
}

/*
 * Prend un { bar: number, baz: boolean } et un préfixe et génère un
 * { 'prefixe/bar': number, 'prefixe/baz': boolean }
 */
type Namespaced<T, N extends string> = {
  [P in keyof T & string as `${N}/${P}`]: T[P];
};

export type Getters = Namespaced<auth.Getters, 'auth'> & Namespaced<version.Getters, 'version'>;

export type Actions = Namespaced<auth.Actions, 'auth'> & Namespaced<version.Actions, 'version'>;

auth.setInitialState(AuthService);

export const store = createStore<State>({
  modules: {
    auth,
    version,
  },

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  plugins: process.env.NODE_ENV === 'production' ? [] : [createLogger<State>()],
});

export const key: InjectionKey<BaseStore<State>> = Symbol('BaseStore');

export type Store = Omit<typeof store, 'getters'> & {
  readonly getters: Getters;
};

export const useStore = (): Store => baseUseStore(key);
