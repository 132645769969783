<template>
  <div>
    <LMap
      ref="map"
      :zoom="zoom"
      :center="center"
      :bounds="bounds"
      @update:zoom="$emit('update:zoom', $event)"
      @update:center="$emit('update:center', $event)"
      @update:bounds="$emit('update:bounds', $event)"
    >
      <LControlLayers />

      <LTileLayer
        v-for="(layer, name) of layers"
        :key="name"
        :name="name"
        :visible="layer.visible"
        :url="layer.url"
        :attribution="layer.attribution"
        layer-type="base"
      ></LTileLayer>

      <slot></slot>
    </LMap>
  </div>
</template>

<script setup lang="ts">
import 'leaflet/dist/leaflet.css';

import type { FitBoundsOptions, LatLngBoundsExpression, LatLngLiteral } from 'leaflet';
import { ref } from 'vue';

withDefaults(defineProps<{ bounds?: LatLngBoundsExpression; center?: LatLngLiteral; zoom?: number }>(), {
  bounds: () => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const returnValue = {} as LatLngBoundsExpression;
    return returnValue;
  },

  center: () => ({ lat: 43.610_228_141_786_43, lng: 3.916_625_976_562_500_4 }),
  zoom: 10,
});

defineEmits<{
  'update:zoom': [zoom: number];
  'update:center': [center: LatLngLiteral];
  'update:bounds': [bounds: LatLngBoundsExpression];
}>();

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const {
  LMap,
  LTileLayer,
  LControlLayers,
  // eslint-disable-next-line @typescript-eslint/no-var-requires, unicorn/prefer-module
} = require('@vue-leaflet/vue-leaflet');

const map = ref<typeof LMap>();

const layers = {
  OpenStreetMap: {
    visible: true,
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',

    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  },

  OpenTopoMap: {
    visible: false,
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',

    attribution:
      'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, '
      + '<a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; '
      + '<a href="https://opentopomap.org">OpenTopoMap</a> '
      + '(<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
  },
};

const fitBounds = (bounds: LatLngBoundsExpression, options: FitBoundsOptions): void => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  map.value.leafletObject.fitBounds(bounds, options);
};

defineExpose({ fitBounds });
</script>
