<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title">Historique des requêtes sur l'API</h4>
    </div>

    <div class="card-content">
      <OLoading :full-page="false" :active="loading" />

      <ONotification v-if="error" variant="danger">
        {{ error }}
      </ONotification>

      <table class="is-striped table" tabindex="false">
        <thead>
          <tr>
            <th>Date</th>

            <th>Requête</th>

            <th>Résultat</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(h, index) of histories" :key="index">
            <td :title="h.date">
              {{ h.date.replace(/T.*/, '') }}<br />

              {{ h.date.replace(/.*T/, '').replace(/\..*/, '') }}<br />
            </td>

            <td>
              <template
                v-if="
                  h.initial_request_data.endpoint === 'eligibility/address/raw/'
                  || h.initial_request_data.endpoint === 'public/address/raw/'
                "
              >
                {{ h.initial_request_data.address }}
              </template>

              <template v-else-if="h.initial_request_data.endpoint === 'eligibility/siret/'">
                <FontAwesomeIcon icon="warehouse" />
                {{ h.initial_request_data.siret }}
              </template>

              <template v-else-if="h.initial_request_data.endpoint === 'eligibility/siren/'">
                <FontAwesomeIcon icon="building" />
                {{ h.initial_request_data.siren }}
              </template>

              <template v-else-if="h.initial_request_data.endpoint === 'eligibility/idban/'">
                {{ h.initial_request_data.idban }}
              </template>

              <template v-else-if="h.initial_request_data.endpoint === 'eligibility/interopkey/'">
                {{ h.initial_request_data.interop_key }}
              </template>

              <template v-else-if="h.initial_request_data.endpoint === 'eligibility/ndi/'">
                {{ h.initial_request_data.ndi }}
              </template>

              <template
                v-else-if="
                  h.initial_request_data.endpoint === 'eligibility/coordinates/'
                  || h.initial_request_data.endpoint === 'public/coordinates/'
                "
              >
                <FontAwesomeIcon icon="map-pin" />
                {{ h.initial_request_data.latitude.toFixed(5) }}°{{ h.initial_request_data.latitude >= 0 ? 'N' : 'S' }}
                {{ h.initial_request_data.longitude.toFixed(5) }}°{{
                  h.initial_request_data.longitude >= 0 ? 'E' : 'O'
                }}
              </template>

              <template v-else-if="h.initial_request_data.endpoint === 'eligibility/address/afnor/'">
                <dl>
                  <template v-for="(value, key) of h.initial_request_data" :key="key">
                    <template v-if="value && key !== 'endpoint'">
                      <dt>{{ key }}</dt>

                      <dd>{{ value }}</dd>
                    </template>
                  </template>
                </dl>
              </template>

              <template v-else>???</template>
            </td>

            <td>
              {{ h.afnor_address }}<br />

              <OButton variant="info" rounded size="small" :title="h.idban" label="idban" />

              <OButton variant="info" rounded size="small" :title="h.interop_key" label="clef interop" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { publicClient } from '@api-sig/client-public';
import type { Components } from '@api-sig/openapi-public';
import ApiError from '@api-sig/vue-api-error';
import { onMounted, ref } from 'vue';

// import Map from '@/components/Map.vue';

// const { LTooltip, LGeoJson } = require('@vue-leaflet/vue-leaflet');

const loading = ref(true);
const histories = ref<Components.Schemas.History[]>([]);
const error = ref('');

const search = async () => {
  loading.value = true;
  error.value = '';
  histories.value = [];

  try {
    const client = await publicClient;
    const resp = await client.eligHistory();

    loading.value = false;

    histories.value = resp.data;
  } catch (error_) {
    error.value = new ApiError(error_).message;
  }
};

onMounted(search);
</script>

<style lang="scss" scoped>
::v-deep(.button) {
  padding: 0.3rem 0.2rem 0.4rem !important;
  height: 1.5em !important;
}
</style>
