import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "column is-3" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "column is-3" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 3 }
const _hoisted_10 = { class: "column is-3" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "column is-3" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["href"]
const _hoisted_16 = { key: 1 }
const _hoisted_17 = ["href"]
const _hoisted_18 = { key: 2 }
const _hoisted_19 = ["href"]
const _hoisted_20 = { key: 3 }
const _hoisted_21 = { class: "columns" }
const _hoisted_22 = { class: "column is-4" }
const _hoisted_23 = { class: "column is-4" }
const _hoisted_24 = { class: "column is-4" }
const _hoisted_25 = { class: "columns" }
const _hoisted_26 = { class: "column is-4" }
const _hoisted_27 = {
  key: 0,
  class: "column is-4"
}
const _hoisted_28 = { class: "columns" }
const _hoisted_29 = {
  key: 0,
  class: "column is-4"
}
const _hoisted_30 = { class: "column is-4" }
const _hoisted_31 = ["href"]
const _hoisted_32 = ["href"]
const _hoisted_33 = ["href"]
const _hoisted_34 = { class: "column is-4" }
const _hoisted_35 = {
  key: 0,
  class: "help is-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OLoading = _resolveComponent("OLoading")!
  const _component_OStepItem = _resolveComponent("OStepItem")!
  const _component_OSelect = _resolveComponent("OSelect")!
  const _component_OField = _resolveComponent("OField")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_OButton = _resolveComponent("OButton")!
  const _component_OInput = _resolveComponent("OInput")!
  const _component_ODatepicker = _resolveComponent("ODatepicker")!
  const _component_OSteps = _resolveComponent("OSteps")!

  return (_openBlock(), _createBlock(_component_OSteps, {
    modelValue: _ctx.step,
    "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.step) = $event)),
    "has-navigation": ""
  }, {
    navigation: _withCtx(({ previous, next }) => [
      _createVNode(_component_OButton, {
        outlined: "",
        variant: "primary",
        "icon-right": "backward",
        disabled: previous.disabled,
        onClick: _withModifiers(previous.action, ["prevent"])
      }, {
        default: _withCtx(() => _cache[53] || (_cache[53] = [
          _createTextVNode(" Revenir ")
        ])),
        _: 2
      }, 1032, ["disabled", "onClick"]),
      _createVNode(_component_OButton, {
        outlined: "",
        variant: _ctx.nextStepDisabled ? 'warning' : 'success',
        "icon-left": "forward",
        disabled: next.disabled || _ctx.nextStepDisabled,
        onClick: _withModifiers(next.action, ["prevent"])
      }, {
        default: _withCtx(() => _cache[54] || (_cache[54] = [
          _createTextVNode(" Étape suivante ")
        ])),
        _: 2
      }, 1032, ["variant", "disabled", "onClick"]),
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("p", _hoisted_35, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_OStepItem, {
        step: 1,
        value: 1,
        label: "Récapitulatif offre",
        clickable: ""
      }, {
        default: _withCtx(() => [
          (_ctx.offer.technical_data)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _cache[27] || (_cache[27] = _createElementVNode("h1", { class: "title" }, "Récapitulatif offre", -1)),
                _createElementVNode("p", null, [
                  _cache[26] || (_cache[26] = _createTextVNode(" Cette commande concerne l'adresse : ")),
                  _createElementVNode("b", null, _toDisplayString(_ctx.offer.technical_data.afnor_address), 1),
                  _createTextVNode(", localisée aux coordonnées " + _toDisplayString(_ctx.$latitude(_ctx.offer.technical_data?.latitude)) + " / " + _toDisplayString(_ctx.$longitude(_ctx.offer.technical_data?.longitude)) + ". ", 1)
                ]),
                _cache[28] || (_cache[28] = _createElementVNode("p", null, "Cette commande comporte les informations suivantes :", -1)),
                _createElementVNode("ul", null, [
                  _createElementVNode("li", null, "Libellé : " + _toDisplayString(_ctx.offer.offer.offer_name), 1),
                  _createElementVNode("li", null, "Engagement : " + _toDisplayString(_ctx.offer.offer.duration_commitment) + " mois", 1),
                  _createElementVNode("li", null, "Setup : " + _toDisplayString(_ctx.offer.offer.nrc) + " €", 1),
                  _createElementVNode("li", null, "Récurrent mensuel : " + _toDisplayString(_ctx.offer.offer.mrc) + " €/mois", 1)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_OLoading, {
                  active: !_ctx.offer.technical_data,
                  "full-page": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_OIcon, {
                      pack: "fas",
                      icon: "sync-alt",
                      size: "large",
                      spin: ""
                    })
                  ]),
                  _: 1
                }, 8, ["active"])
              ]))
        ]),
        _: 1
      }),
      _createVNode(_component_OStepItem, {
        step: 2,
        value: 2,
        label: "Contacts",
        variant: "danger"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order_contacts, (item, idx) => {
            return (_openBlock(), _createElementBlock("div", {
              key: idx,
              class: "columns"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_OField, {
                  label: item.label
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_OSelect, {
                      modelValue: item.id,
                      "onUpdate:modelValue": ($event: any) => ((item.id) = $event),
                      placeholder: "Choisir un contact"
                    }, {
                      default: _withCtx(() => [
                        _cache[29] || (_cache[29] = _createElementVNode("option", null, null, -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contacts, (c) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: c.id,
                            value: c.id
                          }, [
                            (c.type === 'person')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode(_toDisplayString(c.firstname) + " " + _toDisplayString(c.lastname) + " ", 1),
                                  (c.company_name)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createTextVNode("(" + _toDisplayString(c.company_name) + ")", 1)
                                      ], 64))
                                    : _createCommentVNode("", true)
                                ], 64))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(_toDisplayString(c.company_name) + " ", 1),
                                  (c.firstname || c.lastname)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createTextVNode("(" + _toDisplayString(c.firstname) + " " + _toDisplayString(c.lastname) + ")", 1)
                                      ], 64))
                                    : _createCommentVNode("", true)
                                ], 64))
                          ], 8, _hoisted_4))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1032, ["label"])
              ]),
              (item.contact)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("ul", _hoisted_5, [
                      _createElementVNode("li", null, [
                        _createTextVNode(" Société : " + _toDisplayString(item.contact.company_name) + " ", 1),
                        (item.contact.company_department)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode("(" + _toDisplayString(item.contact.company_department) + ")", 1)
                            ], 64))
                          : _createCommentVNode("", true)
                      ]),
                      (item.contact.firstname || item.contact.lastname)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_6, " Personne : " + _toDisplayString(item.contact.firstname) + " " + _toDisplayString(item.contact.lastname), 1))
                        : _createCommentVNode("", true),
                      (item.contact.company_department)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_7, "Service : " + _toDisplayString(item.contact.company_department), 1))
                        : _createCommentVNode("", true),
                      (item.contact.company_siret)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_8, "SIRET : " + _toDisplayString(item.contact.company_siret), 1))
                        : _createCommentVNode("", true),
                      (item.contact.company_vat)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_9, "N° TVA : " + _toDisplayString(item.contact.company_vat), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("ul", _hoisted_10, [
                      _createElementVNode("li", null, _toDisplayString(item.contact.address_line1), 1),
                      (item.contact.address_line2)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_11, _toDisplayString(item.contact.address_line2), 1))
                        : _createCommentVNode("", true),
                      (item.contact.address_line3)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_12, _toDisplayString(item.contact.address_line3), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("li", null, _toDisplayString(item.contact.postcode) + " " + _toDisplayString(item.contact.city) + " " + _toDisplayString(item.contact.country), 1)
                    ]),
                    _createElementVNode("ul", _hoisted_13, [
                      _createElementVNode("li", null, "Représentant : " + _toDisplayString(item.contact.representative_full_name), 1),
                      _createElementVNode("li", null, [
                        _createVNode(_component_FontAwesomeIcon, { icon: "flag" }),
                        _createTextVNode(" " + _toDisplayString(item.contact.representative_language), 1)
                      ]),
                      (item.contact.representative_email)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                            _createElementVNode("a", {
                              href: 'mailto:' + item.contact.representative_email
                            }, [
                              _createVNode(_component_FontAwesomeIcon, { icon: "envelope" }),
                              _createTextVNode(" " + _toDisplayString(item.contact.representative_email), 1)
                            ], 8, _hoisted_15)
                          ]))
                        : _createCommentVNode("", true),
                      (item.contact.representative_mobile_phone)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_16, [
                            _createElementVNode("a", {
                              href: 'tel:' + item.contact.representative_mobile_phone
                            }, [
                              _createVNode(_component_FontAwesomeIcon, { icon: "mobile" }),
                              _createTextVNode(" " + _toDisplayString(item.contact.representative_mobile_phone), 1)
                            ], 8, _hoisted_17)
                          ]))
                        : _createCommentVNode("", true),
                      (item.contact.representative_office_phone)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_18, [
                            _createElementVNode("a", {
                              href: 'tel:' + item.contact.representative_office_phone
                            }, [
                              _createVNode(_component_FontAwesomeIcon, { icon: "phone" }),
                              _createTextVNode(" " + _toDisplayString(item.contact.representative_office_phone), 1)
                            ], 8, _hoisted_19)
                          ]))
                        : _createCommentVNode("", true),
                      (item.contact.representative_role)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_20, _toDisplayString(item.contact.representative_role), 1))
                        : _createCommentVNode("", true)
                    ])
                  ], 64))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
          (!_ctx.showAddContact)
            ? (_openBlock(), _createBlock(_component_OButton, {
                key: 0,
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.showAddContact = !_ctx.showAddContact), ["prevent"]))
              }, {
                default: _withCtx(() => _cache[30] || (_cache[30] = [
                  _createTextVNode("Ajouter un contact")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.showAddContact)
            ? (_openBlock(), _createElementBlock("form", {
                key: 1,
                onSubmit: _cache[19] || (_cache[19] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addContact && _ctx.addContact(...args)), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createVNode(_component_OField, {
                      label: "Type de contact",
                      variant: _ctx.variant(_ctx.contactv$.type),
                      message: _ctx.message(_ctx.contactv$.type),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OSelect, {
                          modelValue: _ctx.contactv$.type.$model,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contactv$.type.$model) = $event))
                        }, {
                          default: _withCtx(() => _cache[31] || (_cache[31] = [
                            _createElementVNode("option", { value: "company" }, "Société", -1),
                            _createElementVNode("option", { value: "person" }, "Personne", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"]),
                    _createVNode(_component_OField, {
                      label: "Société",
                      variant: _ctx.variant(_ctx.contactv$.company_name),
                      message: _ctx.message(_ctx.contactv$.company_name),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.contactv$.company_name.$model,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.contactv$.company_name.$model) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"]),
                    _createVNode(_component_OField, {
                      label: "Prénom",
                      variant: _ctx.variant(_ctx.contactv$.firstname),
                      message: _ctx.message(_ctx.contactv$.firstname),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.contactv$.firstname.$model,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contactv$.firstname.$model) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"]),
                    _createVNode(_component_OField, {
                      label: "Nom",
                      variant: _ctx.variant(_ctx.contactv$.lastname),
                      message: _ctx.message(_ctx.contactv$.lastname),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.contactv$.lastname.$model,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.contactv$.lastname.$model) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"]),
                    _createVNode(_component_OField, {
                      label: "Service de la société",
                      variant: _ctx.variant(_ctx.contactv$.company_department),
                      message: _ctx.message(_ctx.contactv$.company_department),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.contactv$.company_department.$model,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.contactv$.company_department.$model) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"]),
                    _createVNode(_component_OField, {
                      label: "SIRET",
                      variant: _ctx.variant(_ctx.contactv$.company_siret),
                      message: _ctx.message(_ctx.contactv$.company_siret),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.contactv$.company_siret.$model,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.contactv$.company_siret.$model) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"]),
                    _createVNode(_component_OField, {
                      label: "TVA",
                      variant: _ctx.variant(_ctx.contactv$.company_vat),
                      message: _ctx.message(_ctx.contactv$.company_vat),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.contactv$.company_vat.$model,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.contactv$.company_vat.$model) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"])
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createVNode(_component_OField, {
                      label: "Adresse",
                      variant: _ctx.variant(_ctx.contactv$.address_line1),
                      message: _ctx.message(_ctx.contactv$.address_line1),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.address_line,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.address_line) = $event)),
                          type: "textarea",
                          rows: "3"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"]),
                    _createVNode(_component_OField, {
                      label: "Code postal",
                      variant: _ctx.variant(_ctx.contactv$.postcode),
                      message: _ctx.message(_ctx.contactv$.postcode),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.contactv$.postcode.$model,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.contactv$.postcode.$model) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"]),
                    _createVNode(_component_OField, {
                      label: "Ville",
                      variant: _ctx.variant(_ctx.contactv$.city),
                      message: _ctx.message(_ctx.contactv$.city),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.contactv$.city.$model,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.contactv$.city.$model) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"]),
                    _createVNode(_component_OField, {
                      label: "Pays",
                      variant: _ctx.variant(_ctx.contactv$.country),
                      message: _ctx.message(_ctx.contactv$.country),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.contactv$.country.$model,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.contactv$.country.$model) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"])
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(_component_OField, {
                      label: "Nom représentant",
                      variant: _ctx.variant(_ctx.contactv$.representative_full_name),
                      message: _ctx.message(_ctx.contactv$.representative_full_name),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.contactv$.representative_full_name.$model,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.contactv$.representative_full_name.$model) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"]),
                    _createVNode(_component_OField, {
                      label: "Langue",
                      variant: _ctx.variant(_ctx.contactv$.representative_language),
                      message: _ctx.message(_ctx.contactv$.representative_language),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OSelect, {
                          modelValue: _ctx.contactv$.representative_language.$model,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.contactv$.representative_language.$model) = $event))
                        }, {
                          default: _withCtx(() => _cache[32] || (_cache[32] = [
                            _createElementVNode("option", { value: "fr" }, "Français", -1),
                            _createElementVNode("option", { value: "en" }, "English", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"]),
                    _createVNode(_component_OField, {
                      label: "e-mail",
                      variant: _ctx.variant(_ctx.contactv$.representative_email),
                      message: _ctx.message(_ctx.contactv$.representative_email),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.contactv$.representative_email.$model,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.contactv$.representative_email.$model) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"]),
                    _createVNode(_component_OField, {
                      label: "Portable",
                      variant: _ctx.variant(_ctx.contactv$.representative_mobile_phone),
                      message: _ctx.message(_ctx.contactv$.representative_mobile_phone),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.contactv$.representative_mobile_phone.$model,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.contactv$.representative_mobile_phone.$model) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"]),
                    _createVNode(_component_OField, {
                      label: "Tel fixe",
                      variant: _ctx.variant(_ctx.contactv$.representative_office_phone),
                      message: _ctx.message(_ctx.contactv$.representative_office_phone),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.contactv$.representative_office_phone.$model,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.contactv$.representative_office_phone.$model) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"]),
                    _createVNode(_component_OField, {
                      label: "Fonction",
                      variant: _ctx.variant(_ctx.contactv$.representative_role),
                      message: _ctx.message(_ctx.contactv$.representative_role),
                      horizontal: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OInput, {
                          modelValue: _ctx.contactv$.representative_role.$model,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.contactv$.representative_role.$model) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["variant", "message"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, [
                    _createVNode(_component_OField, { horizontal: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_OButton, {
                          variant: "secondary",
                          onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.showAddContact = !_ctx.showAddContact))
                        }, {
                          default: _withCtx(() => _cache[33] || (_cache[33] = [
                            _createTextVNode("Annuler")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_OButton, {
                          "native-type": "submit",
                          variant: "primary"
                        }, {
                          default: _withCtx(() => _cache[34] || (_cache[34] = [
                            _createTextVNode(" Créer le contact ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  (_ctx.contactError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_ctx.contactError), 1))
                    : _createCommentVNode("", true)
                ])
              ], 32))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_OStepItem, {
        step: 3,
        value: 3,
        label: "Informations générales"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_OField, {
            label: "Nom du client final",
            variant: _ctx.variant(_ctx.orderv$.customer_name),
            message: _ctx.message(_ctx.orderv$.customer_name),
            horizontal: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_OInput, {
                modelValue: _ctx.orderv$.customer_name.$model,
                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.orderv$.customer_name.$model) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["variant", "message"]),
          _createVNode(_component_OField, {
            label: "Configuration PE",
            variant: _ctx.variant(_ctx.orderv$.pe_configuration),
            message: _ctx.message(_ctx.orderv$.pe_configuration),
            horizontal: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_OSelect, {
                modelValue: _ctx.orderv$.pe_configuration.$model,
                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.orderv$.pe_configuration.$model) = $event))
              }, {
                default: _withCtx(() => _cache[35] || (_cache[35] = [
                  _createElementVNode("option", { value: "router" }, "Routeur", -1),
                  _createElementVNode("option", { value: "bridge" }, "Bridge", -1)
                ])),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["variant", "message"]),
          _createVNode(_component_OField, {
            label: "Informations complémentaires",
            variant: _ctx.variant(_ctx.orderv$.extra_information),
            message: _ctx.message(_ctx.orderv$.extra_information),
            horizontal: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_OInput, {
                modelValue: _ctx.orderv$.extra_information.$model,
                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.orderv$.extra_information.$model) = $event)),
                type: "textarea"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["variant", "message"]),
          _createVNode(_component_OField, {
            label: "Porte de collecte",
            variant: _ctx.variant(_ctx.orderv$.bitstream_trunk),
            message: _ctx.message(_ctx.orderv$.bitstream_trunk),
            horizontal: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_OInput, {
                modelValue: _ctx.orderv$.bitstream_trunk.$model,
                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.orderv$.bitstream_trunk.$model) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["variant", "message"]),
          _createVNode(_component_OField, {
            label: "Disponibilité sur site",
            variant: _ctx.variant(_ctx.orderv$.premises_availability_date),
            message: _ctx.message(_ctx.orderv$.premises_availability_date),
            horizontal: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ODatepicker, {
                modelValue: _ctx.orderv$.premises_availability_date.$model,
                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.orderv$.premises_availability_date.$model) = $event)),
                "min-date": _ctx.minDate,
                locale: "fr-FR",
                icon: "calendar"
              }, null, 8, ["modelValue", "min-date"])
            ]),
            _: 1
          }, 8, ["variant", "message"])
        ]),
        _: 1
      }),
      _createVNode(_component_OStepItem, {
        step: 4,
        value: 4,
        label: "Récapitulatif Commande"
      }, {
        default: _withCtx(() => [
          _cache[52] || (_cache[52] = _createElementVNode("h1", { class: "title" }, "Récapitulatif Commande", -1)),
          _createElementVNode("div", _hoisted_28, [
            (_ctx.offer.technical_data)
              ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                  _cache[37] || (_cache[37] = _createElementVNode("h3", null, "Offre", -1)),
                  _createElementVNode("p", null, [
                    _cache[36] || (_cache[36] = _createTextVNode(" Cette commande concerne l'adresse : ")),
                    _createElementVNode("b", null, _toDisplayString(_ctx.offer.technical_data.afnor_address), 1),
                    _createTextVNode(", localisée aux coordonnées " + _toDisplayString(_ctx.$latitude(_ctx.offer.technical_data?.latitude)) + " / " + _toDisplayString(_ctx.$longitude(_ctx.offer.technical_data?.longitude)) + ". ", 1)
                  ]),
                  _cache[38] || (_cache[38] = _createElementVNode("p", null, "Cette commande comporte les informations suivantes :", -1)),
                  _createElementVNode("ul", null, [
                    _createElementVNode("li", null, "Libellé : " + _toDisplayString(_ctx.offer.offer.offer_name), 1),
                    _createElementVNode("li", null, "Engagement : " + _toDisplayString(_ctx.offer.offer.duration_commitment) + " mois", 1),
                    _createElementVNode("li", null, "Setup : " + _toDisplayString(_ctx.offer.offer.nrc) + " €", 1),
                    _createElementVNode("li", null, "Récurrent mensuel : " + _toDisplayString(_ctx.offer.offer.mrc) + " €/mois", 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_30, [
              _cache[44] || (_cache[44] = _createElementVNode("h3", null, "Contacts", -1)),
              _createElementVNode("dl", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order_contacts, (item, idx) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
                    (item.contact)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("dt", null, _toDisplayString(item.label), 1),
                          _createElementVNode("dd", null, [
                            (item.contact.type === 'person')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode(_toDisplayString(item.contact.firstname) + " " + _toDisplayString(item.contact.lastname) + " ", 1),
                                  (item.contact.company_name)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createTextVNode(" (" + _toDisplayString(item.contact.company_name) + ") ", 1)
                                      ], 64))
                                    : _createCommentVNode("", true)
                                ], 64))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(_toDisplayString(item.contact.company_name) + " ", 1),
                                  (item.contact.firstname || item.contact.lastname)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createTextVNode(" (" + _toDisplayString(item.contact.firstname) + " " + _toDisplayString(item.contact.lastname) + ") ", 1)
                                      ], 64))
                                    : _createCommentVNode("", true)
                                ], 64)),
                            _cache[40] || (_cache[40] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" " + _toDisplayString(item.contact.representative_full_name), 1),
                            _cache[41] || (_cache[41] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" " + _toDisplayString(item.contact.address_line1) + ", ", 1),
                            (item.contact.address_line2)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                  _createTextVNode(_toDisplayString(item.contact.address_line2) + ", ", 1)
                                ], 64))
                              : _createCommentVNode("", true),
                            (item.contact.address_line3)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                  _createTextVNode(_toDisplayString(item.contact.address_line3) + ", ", 1)
                                ], 64))
                              : _createCommentVNode("", true),
                            _createTextVNode(" " + _toDisplayString(item.contact.postcode) + " " + _toDisplayString(item.contact.city) + " " + _toDisplayString(item.contact.country), 1),
                            _cache[42] || (_cache[42] = _createElementVNode("br", null, null, -1)),
                            (item.contact.representative_email)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                                  _createElementVNode("a", {
                                    href: 'mailto:' + item.contact.representative_email
                                  }, [
                                    _createVNode(_component_FontAwesomeIcon, { icon: "envelope" }),
                                    _createTextVNode(" " + _toDisplayString(item.contact.representative_email), 1)
                                  ], 8, _hoisted_31),
                                  _cache[39] || (_cache[39] = _createElementVNode("br", null, null, -1))
                                ], 64))
                              : _createCommentVNode("", true),
                            (item.contact.representative_mobile_phone)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 5,
                                  href: 'tel:' + item.contact.representative_mobile_phone
                                }, [
                                  _createVNode(_component_FontAwesomeIcon, { icon: "mobile" }),
                                  _createTextVNode(" " + _toDisplayString(item.contact.representative_mobile_phone), 1)
                                ], 8, _hoisted_32))
                              : _createCommentVNode("", true),
                            (item.contact.representative_office_phone)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 6,
                                  href: 'tel:' + item.contact.representative_office_phone
                                }, [
                                  _createVNode(_component_FontAwesomeIcon, { icon: "phone" }),
                                  _createTextVNode(" " + _toDisplayString(item.contact.representative_office_phone), 1)
                                ], 8, _hoisted_33))
                              : _createCommentVNode("", true),
                            _cache[43] || (_cache[43] = _createElementVNode("br", null, null, -1))
                          ])
                        ], 64))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_34, [
              _cache[50] || (_cache[50] = _createElementVNode("h3", null, "Informations complémentaires", -1)),
              _createElementVNode("dl", null, [
                _cache[49] || (_cache[49] = _createElementVNode("dt", null, "Nom du client", -1)),
                _createElementVNode("dd", null, _toDisplayString(_ctx.newOrder.customer_name), 1),
                (_ctx.newOrder.pe_configuration)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _cache[45] || (_cache[45] = _createElementVNode("dt", null, "Configuration PE", -1)),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.newOrder.pe_configuration), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.newOrder.extra_information)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _cache[46] || (_cache[46] = _createElementVNode("dt", null, "Informations complémentaires", -1)),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.newOrder.extra_information), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.newOrder.bitstream_trunk)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _cache[47] || (_cache[47] = _createElementVNode("dt", null, "Porte de collecte", -1)),
                      _createElementVNode("dd", null, _toDisplayString(_ctx.newOrder.bitstream_trunk), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.newOrder.premises_availability_date)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                      _cache[48] || (_cache[48] = _createElementVNode("dt", null, "Disponibilité sur site", -1)),
                      _createElementVNode("dd", null, _toDisplayString(typeof _ctx.newOrder.premises_availability_date === 'string'
                    ? _ctx.newOrder.premises_availability_date
                    : (_ctx.newOrder.premises_availability_date as unknown as Date).toISOString().slice(0, 10)), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createVNode(_component_OField, { horizontal: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_OButton, {
                variant: "primary",
                "icon-left": "check",
                onClick: _withModifiers(_ctx.submitOrder, ["prevent"])
              }, {
                default: _withCtx(() => _cache[51] || (_cache[51] = [
                  _createTextVNode(" Créer la commande ")
                ])),
                _: 1
              }, 8, ["onClick"]),
              _createTextVNode(" " + _toDisplayString(_ctx.orderError), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}