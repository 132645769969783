<template>
  <section>
    <form name="form" @submit.prevent="handleLogin">
      <OField horizontal label="Email">
        <OInput v-model="user.email" type="email" expanded data-test="email" />
      </OField>

      <OField horizontal label="Password">
        <OInput v-model="user.password" type="password" expanded data-test="password" />
      </OField>

      <OField horizontal>
        <OButton label="Login" native-type="submit" data-test="submit" @click="handleLogin" />

        <OButton label="Mot de passe oublié" native-type="submit" data-test="recover" @click.prevent="resetPassword" />
      </OField>

      <OLoading :full-page="false" :active="loading" />

      <ONotification v-if="message" variant="danger" data-test="notification">
        {{ message }}
      </ONotification>
    </form>
  </section>
</template>

<script setup lang="ts">
import { publicClient } from '@api-sig/client-public';
import type { Paths } from '@api-sig/openapi-public';
import ApiError from '@api-sig/vue-api-error';
import { computed, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useStore } from '@/store';

defineOptions({ name: 'Login' });

const store = useStore();
const router = useRouter();

const user = reactive<Paths.AuthAuth.QueryParameters>({
  email: '',
  password: '',
});

const loading = ref(false);

const message = ref('');

const loggedIn = computed(() => store.getters['auth/loggedIn']);

// @Auth.Action login!: Actions['auth/login'];

const handleLogin = async () => {
  loading.value = true;

  if (user.email && user.password) {
    try {
      message.value = '';

      await store.dispatch('auth/login', user);

      loading.value = false;

      await router.push({ name: 'Recherche' });
    } catch (error) {
      loading.value = false;

      message.value = new ApiError(error).message;
    }
  } else {
    message.value = 'Email or password missing';
    loading.value = false;
  }
};

const resetPassword = async () => {
  if (user.email) {
    const client = await publicClient;
    await client.authRecoverSend({ email: user.email });
    message.value = 'Email envoyé';
  } else {
    message.value = 'Email missing';
  }
};

onMounted(async () => {
  if (loggedIn.value) {
    await router.push({ name: 'Recherche' });
  }
});
</script>

<style lang="scss" scoped>
section {
  margin-top: 5%;
  justify-self: center;
  width: 400px;
}
::v-deep(.field.is-horizontal .field-label) {
  flex-grow: 2;
}
.notification {
  margin-top: 1em;
  padding: 0.8em 1em;
}
</style>
