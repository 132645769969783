import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "columns" }
const _hoisted_2 = {
  key: 0,
  class: "column is-4"
}
const _hoisted_3 = { class: "column is-4" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "column is-4" }

import { publicClient } from '@api-sig/client-public';
import type { Components } from '@api-sig/openapi-public';
import { onMounted, reactive, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Commande',
  props: {
    orderId: {}
  },
  setup(__props: any) {

const props = __props;

const order = ref({} as Components.Schemas.Order);
const offer = ref({} as Components.Schemas.FullOffer);
const orderContacts = reactive<Record<string, Components.Schemas.Contact | undefined>>({});

const getOrder = async () => {
  const client = await publicClient;

  const resp = await client.orderList({ id: props.orderId });

  if (resp.data !== undefined) {
    order.value = resp.data[0];

    orderContacts['Contact sur site'] = order.value.installation_site_contact;

    orderContacts['Contact technique'] = order.value.technical_contact;
    orderContacts['Contact facturation'] = order.value.billing_contact;

    const resp2 = await client.offerList({
      uniqid: order.value.offers[0].uniqid,
    });

    if (resp2.data !== undefined) {
      offer.value = resp2.data;
    }
  }
};

onMounted(getOrder);

return (_ctx: any,_cache: any) => {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (order.value.id)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _cache[15] || (_cache[15] = _createElementVNode("h1", { class: "title" }, "Confirmation de la Commande", -1)),
        _createElementVNode("div", _hoisted_1, [
          (offer.value.technical_data)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Offre", -1)),
                _createElementVNode("p", null, [
                  _cache[0] || (_cache[0] = _createTextVNode(" Cette commande concerne l'adresse : ")),
                  _createElementVNode("b", null, _toDisplayString(offer.value.technical_data.afnor_address), 1),
                  _createTextVNode(", localisée aux coordonnées " + _toDisplayString(_ctx.$latitude(offer.value.technical_data?.latitude)) + " / " + _toDisplayString(_ctx.$longitude(offer.value.technical_data?.longitude)) + ". ", 1)
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("p", null, "Cette commande comporte les informations suivantes :", -1)),
                _createElementVNode("ul", null, [
                  _createElementVNode("li", null, "Libellé : " + _toDisplayString(offer.value.offer.offer_name), 1),
                  _createElementVNode("li", null, "Engagement : " + _toDisplayString(offer.value.offer.duration_commitment) + " mois", 1),
                  _createElementVNode("li", null, "Setup : " + _toDisplayString(offer.value.offer.nrc) + " €", 1),
                  _createElementVNode("li", null, "Récurrent mensuel : " + _toDisplayString(offer.value.offer.mrc) + " €/mois", 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _cache[8] || (_cache[8] = _createElementVNode("h3", null, "Contacts", -1)),
            _createElementVNode("dl", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderContacts, (contact, key) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                  contact
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("dt", null, _toDisplayString(key), 1),
                        _createElementVNode("dd", null, [
                          (contact.type === 'person')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(contact.firstname) + " " + _toDisplayString(contact.lastname) + " ", 1),
                                (contact.company_name)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createTextVNode("(" + _toDisplayString(contact.company_name) + ")", 1)
                                    ], 64))
                                  : _createCommentVNode("", true)
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(contact.company_name) + " ", 1),
                                (contact.firstname || contact.lastname)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createTextVNode(" (" + _toDisplayString(contact.firstname) + " " + _toDisplayString(contact.lastname) + ") ", 1)
                                    ], 64))
                                  : _createCommentVNode("", true)
                              ], 64)),
                          _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" " + _toDisplayString(contact.representative_full_name), 1),
                          _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" " + _toDisplayString(contact.address_line1) + ", ", 1),
                          (contact.address_line2)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                _createTextVNode(_toDisplayString(contact.address_line2) + ", ", 1)
                              ], 64))
                            : _createCommentVNode("", true),
                          (contact.address_line3)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                _createTextVNode(_toDisplayString(contact.address_line3) + ", ", 1)
                              ], 64))
                            : _createCommentVNode("", true),
                          _createTextVNode(" " + _toDisplayString(contact.postcode) + " " + _toDisplayString(contact.city) + " " + _toDisplayString(contact.country), 1),
                          _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                          (contact.representative_email)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                                _createElementVNode("a", {
                                  href: 'mailto:' + contact.representative_email
                                }, [
                                  _createVNode(_component_FontAwesomeIcon, { icon: "envelope" }),
                                  _createTextVNode(" " + _toDisplayString(contact.representative_email), 1)
                                ], 8, _hoisted_4),
                                _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
                              ], 64))
                            : _createCommentVNode("", true),
                          (contact.representative_mobile_phone)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 5,
                                href: 'tel:' + contact.representative_mobile_phone
                              }, [
                                _createVNode(_component_FontAwesomeIcon, { icon: "mobile" }),
                                _createTextVNode(" " + _toDisplayString(contact.representative_mobile_phone), 1)
                              ], 8, _hoisted_5))
                            : _createCommentVNode("", true),
                          (contact.representative_office_phone)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 6,
                                href: 'tel:' + contact.representative_office_phone
                              }, [
                                _createVNode(_component_FontAwesomeIcon, { icon: "phone" }),
                                _createTextVNode(" " + _toDisplayString(contact.representative_office_phone), 1)
                              ], 8, _hoisted_6))
                            : _createCommentVNode("", true),
                          _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1))
                        ])
                      ], 64))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[14] || (_cache[14] = _createElementVNode("h3", null, "Informations complémentaires", -1)),
            _createElementVNode("dl", null, [
              _cache[13] || (_cache[13] = _createElementVNode("dt", null, "Nom du client", -1)),
              _createElementVNode("dd", null, _toDisplayString(order.value.customer_name), 1),
              (order.value.pe_configuration)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[9] || (_cache[9] = _createElementVNode("dt", null, "Configuration PE", -1)),
                    _createElementVNode("dd", null, _toDisplayString(order.value.pe_configuration), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (order.value.extra_information)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _cache[10] || (_cache[10] = _createElementVNode("dt", null, "Informations complémentaires", -1)),
                    _createElementVNode("dd", null, _toDisplayString(order.value.extra_information), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (order.value.bitstream_trunk)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _cache[11] || (_cache[11] = _createElementVNode("dt", null, "Porte de collecte", -1)),
                    _createElementVNode("dd", null, _toDisplayString(order.value.bitstream_trunk), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (order.value.premises_availability_date)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    _cache[12] || (_cache[12] = _createElementVNode("dt", null, "Disponibilité sur site", -1)),
                    _createElementVNode("dd", null, _toDisplayString(new Date(order.value.premises_availability_date).toISOString().slice(0, 10)), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ], 64))
    : _createCommentVNode("", true)
}
}

})