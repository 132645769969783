import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-content" }
const _hoisted_3 = {
  class: "is-striped table",
  tabindex: "false"
}
const _hoisted_4 = ["title"]
const _hoisted_5 = { key: 7 }

import { publicClient } from '@api-sig/client-public';
import type { Components } from '@api-sig/openapi-public';
import ApiError from '@api-sig/vue-api-error';
import { onMounted, ref } from 'vue';

// import Map from '@/components/Map.vue';

// const { LTooltip, LGeoJson } = require('@vue-leaflet/vue-leaflet');


export default /*@__PURE__*/_defineComponent({
  __name: 'Historique',
  setup(__props) {

const loading = ref(true);
const histories = ref<Components.Schemas.History[]>([]);
const error = ref('');

const search = async () => {
  loading.value = true;
  error.value = '';
  histories.value = [];

  try {
    const client = await publicClient;
    const resp = await client.eligHistory();

    loading.value = false;

    histories.value = resp.data;
  } catch (error_) {
    error.value = new ApiError(error_).message;
  }
};

onMounted(search);

return (_ctx: any,_cache: any) => {
  const _component_OLoading = _resolveComponent("OLoading")!
  const _component_ONotification = _resolveComponent("ONotification")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_OButton = _resolveComponent("OButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-header" }, [
      _createElementVNode("h4", { class: "card-header-title" }, "Historique des requêtes sur l'API")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_OLoading, {
        "full-page": false,
        active: loading.value
      }, null, 8, ["active"]),
      (error.value)
        ? (_openBlock(), _createBlock(_component_ONotification, {
            key: 0,
            variant: "danger"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(error.value), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("table", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, "Date"),
            _createElementVNode("th", null, "Requête"),
            _createElementVNode("th", null, "Résultat")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(histories.value, (h, index) => {
            return (_openBlock(), _createElementBlock("tr", { key: index }, [
              _createElementVNode("td", {
                title: h.date
              }, [
                _createTextVNode(_toDisplayString(h.date.replace(/T.*/, '')), 1),
                _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(h.date.replace(/.*T/, '').replace(/\..*/, '')), 1),
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
              ], 8, _hoisted_4),
              _createElementVNode("td", null, [
                (
                  h.initial_request_data.endpoint === 'eligibility/address/raw/'
                  || h.initial_request_data.endpoint === 'public/address/raw/'
                )
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(h.initial_request_data.address), 1)
                    ], 64))
                  : (h.initial_request_data.endpoint === 'eligibility/siret/')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createVNode(_component_FontAwesomeIcon, { icon: "warehouse" }),
                        _createTextVNode(" " + _toDisplayString(h.initial_request_data.siret), 1)
                      ], 64))
                    : (h.initial_request_data.endpoint === 'eligibility/siren/')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          _createVNode(_component_FontAwesomeIcon, { icon: "building" }),
                          _createTextVNode(" " + _toDisplayString(h.initial_request_data.siren), 1)
                        ], 64))
                      : (h.initial_request_data.endpoint === 'eligibility/idban/')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                            _createTextVNode(_toDisplayString(h.initial_request_data.idban), 1)
                          ], 64))
                        : (h.initial_request_data.endpoint === 'eligibility/interopkey/')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                              _createTextVNode(_toDisplayString(h.initial_request_data.interop_key), 1)
                            ], 64))
                          : (h.initial_request_data.endpoint === 'eligibility/ndi/')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                                _createTextVNode(_toDisplayString(h.initial_request_data.ndi), 1)
                              ], 64))
                            : (
                  h.initial_request_data.endpoint === 'eligibility/coordinates/'
                  || h.initial_request_data.endpoint === 'public/coordinates/'
                )
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                                  _createVNode(_component_FontAwesomeIcon, { icon: "map-pin" }),
                                  _createTextVNode(" " + _toDisplayString(h.initial_request_data.latitude.toFixed(5)) + "°" + _toDisplayString(h.initial_request_data.latitude >= 0 ? 'N' : 'S') + " " + _toDisplayString(h.initial_request_data.longitude.toFixed(5)) + "°" + _toDisplayString(h.initial_request_data.longitude >= 0 ? 'E' : 'O'), 1)
                                ], 64))
                              : (h.initial_request_data.endpoint === 'eligibility/address/afnor/')
                                ? (_openBlock(), _createElementBlock("dl", _hoisted_5, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(h.initial_request_data, (value, key) => {
                                      return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                                        (value && key !== 'endpoint')
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                              _createElementVNode("dt", null, _toDisplayString(key), 1),
                                              _createElementVNode("dd", null, _toDisplayString(value), 1)
                                            ], 64))
                                          : _createCommentVNode("", true)
                                      ], 64))
                                    }), 128))
                                  ]))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 8 }, [
                                    _createTextVNode("???")
                                  ], 64))
              ]),
              _createElementVNode("td", null, [
                _createTextVNode(_toDisplayString(h.afnor_address), 1),
                _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                _createVNode(_component_OButton, {
                  variant: "info",
                  rounded: "",
                  size: "small",
                  title: h.idban,
                  label: "idban"
                }, null, 8, ["title"]),
                _createVNode(_component_OButton, {
                  variant: "info",
                  rounded: "",
                  size: "small",
                  title: h.interop_key,
                  label: "clef interop"
                }, null, 8, ["title"])
              ])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}
}

})