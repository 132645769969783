import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "columns" }
const _hoisted_2 = { class: "column is-5" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "column is-7" }
const _hoisted_5 = {
  key: 0,
  class: "columns"
}
const _hoisted_6 = { class: "column is-12" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 1,
  class: "columns"
}

import { publicClient } from '@api-sig/client-public';
import type { Components, Paths } from '@api-sig/openapi-public';
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import MyMap from '@/components/Map.vue';

export type Row = Components.Schemas.Offer;


export default /*@__PURE__*/_defineComponent({
  ...{ name: 'Recherche' },
  __name: 'Recherche',
  setup(__props) {



// eslint-disable-next-line @typescript-eslint/no-var-requires, unicorn/prefer-module, @typescript-eslint/no-unsafe-assignment
const { LTooltip, LGeoJson } = require('@vue-leaflet/vue-leaflet');

const router = useRouter();

const activeTab = ref('raw');
const loading = ref(false);
const siret = ref('');
const siren = ref('');
const idban = ref('');
const interopkey = ref('');
const raw = ref('');
const error = ref('');
const isFetching = ref(false);
const gtr = ref<string[]>([]);
const guaranteedBandwidth = ref<string[]>([]);

const rawData = ref<Components.Schemas.PublicSearchItem[]>([]);

const coordinates = reactive<Paths.EligCoordinates.QueryParameters>({
  latitude: '',
  longitude: '',
});

const afnor = reactive<Paths.EligAfnor.QueryParameters>({
  housenumber: '',
  rep: '',
  streetname: '',
  cityname: '',
  postcode: '',
  citycode: '',
});

const result = ref<Components.Schemas.EligibilityAnswer | Record<never, never>>({});

/*
 * Ne sort que les offres qui correspondent au filtre,
 * puis les pricelists non vides
 */
const filterByOffer = (
  pricelist: Components.Schemas.Pricelist[],
  filter: (o: Components.Schemas.Offer) => boolean
): Components.Schemas.Pricelist[] =>
  pricelist
    .map((p) => ({
      pricelist_name: p.pricelist_name,
      offers: p.offers.filter((o) => filter(o)),
    }))
    .filter((p) => p.offers.length > 0);

const filteredPricelist = computed<Components.Schemas.Pricelist[]>(() => {
  if (!('pricelists' in result.value)) {
    return [];
  }

  let returnValue = result.value.pricelists;

  returnValue = filterByOffer(returnValue, (o) => gtr.value.includes(o.gtr));

  returnValue = filterByOffer(returnValue, (o) => guaranteedBandwidth.value.includes(o.guaranteed_bandwidth));

  return returnValue;
});

const allGtrs = computed<string[]>(() => {
  if (!('pricelists' in result.value)) {
    return [];
  }

  return [...new Set(result.value.pricelists.flatMap((p) => p.offers.map((o) => o.gtr)))];
});

const allGuaranteedBandwidths = computed<string[]>(() => {
  if (!('pricelists' in result.value)) {
    return [];
  }

  return [...new Set(result.value.pricelists.flatMap((p) => p.offers.map((o) => o.guaranteed_bandwidth)))];
});

const submitCorrect = async () => {
  const client = await publicClient;

  switch (activeTab.value) {
    case 'raw': {
      return client.eligRaw({ address: raw.value });
    }

    case 'afnor': {
      const clone: Paths.EligAfnor.QueryParameters = { ...afnor };

      let k: keyof typeof clone;

      for (k in clone) {
        if (clone[k] === '') {
          delete clone[k];
        }
      }

      return client.eligAfnor(clone);
    }

    case 'siret': {
      return client.eligSiret({ siret: siret.value.replaceAll(/\s+/g, '') });
    }

    case 'siren': {
      return client.eligSiren({ siren: siren.value.replaceAll(/\s+/g, '') });
    }

    case 'idban': {
      return client.eligIdban({ idban: idban.value });
    }

    case 'interopkey': {
      return client.eligInteropkey({ interop_key: interopkey.value });
    }

    case 'coordinates': {
      return client.eligCoordinates(coordinates);
    }

    default: {
      throw new Error('should not happen');
    }
  }
};

const submit = async () => {
  try {
    error.value = '';

    loading.value = true;

    const resp = await submitCorrect();

    result.value = resp.data;
    gtr.value = allGtrs.value;
    guaranteedBandwidth.value = allGuaranteedBandwidths.value;
  } catch (error_) {
    result.value = {};

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    error.value = error_ instanceof Error ? error_.message : `unknown error: ${error_}`;
  } finally {
    loading.value = false;
  }
};

const searchRawData = async (address: string) => {
  isFetching.value = true;

  const client = await publicClient;

  try {
    const resp = await client.publicAddressSearch({ address });

    rawData.value = resp.data;
    isFetching.value = false;
  } catch {
    // rien
  }
};

const commander = async (offer: Components.Schemas.Offer) => {
  // TODO: utiliser une fenêtre modale
  // eslint-disable-next-line no-alert, no-restricted-globals
  if (confirm(`Commander l'offre ${offer.offer_name} ?`)) {
    await router.push({
      name: 'NewCommande',
      query: { offerId: offer.uniqid },
    });
    // go there
  }
};

return (_ctx: any,_cache: any) => {
  const _component_OAutocomplete = _resolveComponent("OAutocomplete")!
  const _component_OField = _resolveComponent("OField")!
  const _component_OButton = _resolveComponent("OButton")!
  const _component_OTabItem = _resolveComponent("OTabItem")!
  const _component_OInput = _resolveComponent("OInput")!
  const _component_OTabs = _resolveComponent("OTabs")!
  const _component_ONotification = _resolveComponent("ONotification")!
  const _component_OCheckbox = _resolveComponent("OCheckbox")!
  const _component_OTableColumn = _resolveComponent("OTableColumn")!
  const _component_OTable = _resolveComponent("OTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_OTabs, {
          modelValue: activeTab.value,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((activeTab).value = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_OTabItem, {
              value: "raw",
              label: "Adresse brute"
            }, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  onSubmit: _withModifiers(submit, ["prevent"])
                }, [
                  _createVNode(_component_OField, {
                    horizontal: "",
                    label: "Adresse brute"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OAutocomplete, {
                        modelValue: raw.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((raw).value = $event)),
                        data: rawData.value,
                        loading: isFetching.value,
                        "debounce-typing": 500,
                        field: "afnor_address",
                        placeholder: "12 rue de la paix 75001 paris",
                        expanded: "",
                        onSelect: submit,
                        onTyping: searchRawData
                      }, null, 8, ["modelValue", "data", "loading"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_OField, { horizontal: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OButton, {
                        label: "Submit",
                        "native-type": "submit",
                        class: _normalizeClass({ 'is-loading': loading.value }),
                        disabled: loading.value
                      }, null, 8, ["class", "disabled"])
                    ]),
                    _: 1
                  })
                ], 32)
              ]),
              _: 1
            }),
            _createVNode(_component_OTabItem, {
              value: "afnor",
              label: "AFNOR"
            }, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  onSubmit: _withModifiers(submit, ["prevent"])
                }, [
                  _createVNode(_component_OField, {
                    horizontal: "",
                    label: "N° de maison"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OInput, {
                        modelValue: afnor.housenumber,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((afnor.housenumber) = $event)),
                        placeholder: "12"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_OField, {
                    horizontal: "",
                    label: "Répétition"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OInput, {
                        modelValue: afnor.rep,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((afnor.rep) = $event)),
                        placeholder: "bis"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_OField, {
                    horizontal: "",
                    label: "Non de rue"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OInput, {
                        modelValue: afnor.streetname,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((afnor.streetname) = $event)),
                        placeholder: "rue de la paix"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_OField, {
                    horizontal: "",
                    label: "Ville"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OInput, {
                        modelValue: afnor.cityname,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((afnor.cityname) = $event)),
                        placeholder: "Paris"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_OField, {
                    horizontal: "",
                    label: "Code postal"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OInput, {
                        modelValue: afnor.postcode,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((afnor.postcode) = $event)),
                        placeholder: "75016"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_OField, {
                    horizontal: "",
                    label: "Code INSEE"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OInput, {
                        modelValue: afnor.citycode,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((afnor.citycode) = $event)),
                        placeholder: "75116"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_OField, { horizontal: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OButton, {
                        label: "Submit",
                        "native-type": "submit",
                        class: _normalizeClass({ 'is-loading': loading.value }),
                        disabled: loading.value
                      }, null, 8, ["class", "disabled"])
                    ]),
                    _: 1
                  })
                ], 32)
              ]),
              _: 1
            }),
            _createVNode(_component_OTabItem, {
              value: "siret",
              label: "SIRET"
            }, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  onSubmit: _withModifiers(submit, ["prevent"])
                }, [
                  _createVNode(_component_OField, {
                    horizontal: "",
                    label: "Siret"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OInput, {
                        modelValue: siret.value,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((siret).value = $event)),
                        placeholder: "441 938 222 00037"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_OField, { horizontal: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OButton, {
                        label: "Submit",
                        "native-type": "submit",
                        class: _normalizeClass({ 'is-loading': loading.value }),
                        disabled: loading.value
                      }, null, 8, ["class", "disabled"])
                    ]),
                    _: 1
                  })
                ], 32)
              ]),
              _: 1
            }),
            _createVNode(_component_OTabItem, {
              value: "siren",
              label: "SIREN"
            }, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  onSubmit: _withModifiers(submit, ["prevent"])
                }, [
                  _createVNode(_component_OField, {
                    horizontal: "",
                    label: "SIREN"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OInput, {
                        modelValue: siren.value,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((siren).value = $event)),
                        placeholder: "441 938 222"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_OField, { horizontal: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OButton, {
                        label: "Submit",
                        "native-type": "submit",
                        class: _normalizeClass({ 'is-loading': loading.value }),
                        disabled: loading.value
                      }, null, 8, ["class", "disabled"])
                    ]),
                    _: 1
                  })
                ], 32)
              ]),
              _: 1
            }),
            _createVNode(_component_OTabItem, {
              value: "idban",
              label: "IDBAN"
            }, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  onSubmit: _withModifiers(submit, ["prevent"])
                }, [
                  _createVNode(_component_OField, {
                    horizontal: "",
                    label: "IDBAN"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OInput, {
                        modelValue: idban.value,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((idban).value = $event)),
                        placeholder: "ADRNIVX_0000000272828236"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_OField, { horizontal: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OButton, {
                        label: "Submit",
                        "native-type": "submit",
                        class: _normalizeClass({ 'is-loading': loading.value }),
                        disabled: loading.value
                      }, null, 8, ["class", "disabled"])
                    ]),
                    _: 1
                  })
                ], 32)
              ]),
              _: 1
            }),
            _createVNode(_component_OTabItem, {
              value: "interopkey",
              label: "Interop Key"
            }, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  onSubmit: _withModifiers(submit, ["prevent"])
                }, [
                  _createVNode(_component_OField, {
                    horizontal: "",
                    label: "Interop Key"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OInput, {
                        modelValue: interopkey.value,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((interopkey).value = $event)),
                        placeholder: "76540_0850_00053_bis"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_OField, { horizontal: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OButton, {
                        label: "Submit",
                        "native-type": "submit",
                        class: _normalizeClass({ 'is-loading': loading.value }),
                        disabled: loading.value
                      }, null, 8, ["class", "disabled"])
                    ]),
                    _: 1
                  })
                ], 32)
              ]),
              _: 1
            }),
            _createVNode(_component_OTabItem, {
              value: "coordinates",
              label: "Coordonnées"
            }, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  onSubmit: _withModifiers(submit, ["prevent"])
                }, [
                  _createVNode(_component_OField, {
                    horizontal: "",
                    label: "Longitude (est/ouest)"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OInput, {
                        modelValue: coordinates.longitude,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((coordinates.longitude) = $event)),
                        placeholder: "1.09146837396795"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_OField, {
                    horizontal: "",
                    label: "Latitude (nord/sud)"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OInput, {
                        modelValue: coordinates.latitude,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((coordinates.latitude) = $event)),
                        placeholder: "49.4737467145085"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_OField, { horizontal: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_OButton, {
                        label: "Submit",
                        "native-type": "submit",
                        class: _normalizeClass({ 'is-loading': loading.value }),
                        disabled: loading.value
                      }, null, 8, ["class", "disabled"])
                    ]),
                    _: 1
                  })
                ], 32)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        (error.value)
          ? (_openBlock(), _createBlock(_component_ONotification, {
              key: 0,
              variant: "danger"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(error.value), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        ('eligibility' in result.value)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
              _createElementVNode("li", null, "Eligibilité : " + _toDisplayString(result.value.eligibility), 1),
              _createElementVNode("li", null, [
                _cache[16] || (_cache[16] = _createTextVNode(" Données techniques ")),
                _createElementVNode("ul", null, [
                  _createElementVNode("li", null, "Adresse AFNOR : " + _toDisplayString(result.value.technical_data.afnor_address), 1),
                  _createElementVNode("li", null, "Latitude : " + _toDisplayString(result.value.technical_data.latitude), 1),
                  _createElementVNode("li", null, "Longitude : " + _toDisplayString(result.value.technical_data.longitude), 1),
                  _createElementVNode("li", null, "IDBAN : " + _toDisplayString(result.value.technical_data.idban), 1),
                  _createElementVNode("li", null, "Interop Key : " + _toDisplayString(result.value.technical_data.interop_key), 1),
                  (result.value.eligibility)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("li", null, "Distance : " + _toDisplayString(result.value.technical_data.distance), 1),
                        _createElementVNode("li", null, "Pricing : " + _toDisplayString(result.value.technical_data.pricing), 1),
                        _createElementVNode("li", null, "Accuracy : " + _toDisplayString(result.value.technical_data.accuracy), 1),
                        _createElementVNode("li", null, "Zone name : " + _toDisplayString(result.value.technical_data.zone_name), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        ('eligibility' in result.value)
          ? (_openBlock(), _createBlock(MyMap, {
              key: 0,
              class: "map-size",
              zoom: 12,
              center: {
            lat: result.value.technical_data.latitude,
            lng: result.value.technical_data.longitude,
          }
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(LGeoJson), {
                  geojson: {
              type: 'Point',
              coordinates: [result.value.technical_data.longitude, result.value.technical_data.latitude],
            }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(LTooltip), null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(result.value.technical_data.afnor_address), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["geojson"]),
                (activeTab.value === 'coordinates' && coordinates.longitude && coordinates.latitude)
                  ? (_openBlock(), _createBlock(_unref(LGeoJson), {
                      key: 0,
                      geojson: {
              type: 'Point',
              coordinates: [coordinates.longitude, coordinates.latitude],
            }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(LTooltip), null, {
                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                            _createTextVNode("Recherche")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["geojson"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["center"]))
          : _createCommentVNode("", true)
      ])
    ]),
    ('eligibility' in result.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (result.value.eligibility)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _cache[21] || (_cache[21] = _createElementVNode("h5", null, "Filtres", -1)),
                  _createElementVNode("ul", null, [
                    (allGtrs.value.length > 1)
                      ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                          _cache[18] || (_cache[18] = _createTextVNode(" GTR : ")),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allGtrs.value, (g) => {
                            return (_openBlock(), _createBlock(_component_OCheckbox, {
                              key: g,
                              modelValue: gtr.value,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((gtr).value = $event)),
                              "native-value": g
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(g), 1)
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "native-value"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (allGuaranteedBandwidths.value.length > 1)
                      ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                          _cache[19] || (_cache[19] = _createTextVNode(" BP Garantie : ")),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allGuaranteedBandwidths.value, (g) => {
                            return (_openBlock(), _createBlock(_component_OCheckbox, {
                              key: g,
                              modelValue: guaranteedBandwidth.value,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((guaranteedBandwidth).value = $event)),
                              "native-value": g
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(g), 1)
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "native-value"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredPricelist.value, (pricelist, idx) => {
                    return (_openBlock(), _createElementBlock("div", { key: idx }, [
                      _createElementVNode("h5", null, "Prix pour : " + _toDisplayString(pricelist.pricelist_name), 1),
                      _createVNode(_component_OTable, {
                        data: pricelist.offers,
                        striped: "",
                        narrowed: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_OTableColumn, {
                            field: "row.offer_name",
                            label: "Offre",
                            sortable: ""
                          }, {
                            default: _withCtx(({ row }: { row: Row }) => [
                              _createTextVNode(_toDisplayString(row.offer_name), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_OTableColumn, {
                            field: "row.technology",
                            label: "Techno",
                            sortable: ""
                          }, {
                            default: _withCtx(({ row }: { row: Row }) => [
                              _createTextVNode(_toDisplayString(row.technology), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_OTableColumn, {
                            field: "row.guaranteed_bandwidth",
                            label: "BP Garantie",
                            sortable: ""
                          }, {
                            default: _withCtx(({ row }: { row: Row }) => [
                              _createTextVNode(_toDisplayString(row.guaranteed_bandwidth), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_OTableColumn, {
                            field: "row.maximum_bandwidth",
                            label: "BP Max",
                            sortable: ""
                          }, {
                            default: _withCtx(({ row }: { row: Row }) => [
                              _createTextVNode(_toDisplayString(row.maximum_bandwidth), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_OTableColumn, {
                            field: "row.duration_commitment",
                            label: "Engagement",
                            sortable: ""
                          }, {
                            default: _withCtx(({ row }: { row: Row }) => [
                              _createTextVNode(_toDisplayString(row.duration_commitment) + " mois ", 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_OTableColumn, {
                            field: "row.estimated_commissioning_delay",
                            label: "Livraison",
                            sortable: ""
                          }, {
                            default: _withCtx(({ row }: { row: Row }) => [
                              _createTextVNode(_toDisplayString(row.estimated_commissioning_delay) + " mois ", 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_OTableColumn, {
                            field: "row.gtd",
                            label: "Dispo",
                            sortable: ""
                          }, {
                            default: _withCtx(({ row }: { row: Row }) => [
                              _createTextVNode(_toDisplayString(row.gtd) + "% ", 1)
                            ]),
                            _: 1
                          }),
                          false
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64))
                            : _createCommentVNode("", true),
                          _createVNode(_component_OTableColumn, {
                            field: "row.gtr",
                            label: "GTR",
                            sortable: ""
                          }, {
                            default: _withCtx(({ row }: { row: Row }) => [
                              _createTextVNode(_toDisplayString(row.gtr), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_OTableColumn, {
                            field: "row.mrc",
                            label: "Prix Revendeur",
                            sortable: ""
                          }, {
                            default: _withCtx(({ row }: { row: Row }) => [
                              _createElementVNode("b", null, "FAS " + _toDisplayString(row.nrc) + " €", 1),
                              _cache[20] || (_cache[20] = _createTextVNode(" + ")),
                              _createElementVNode("b", null, _toDisplayString(row.mrc) + " €/mois", 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_OTableColumn, {
                            field: "row.pmrc",
                            label: "Prix Public",
                            sortable: ""
                          }, {
                            default: _withCtx(({ row }: { row: Row }) => [
                              _createTextVNode(" FAS " + _toDisplayString(row.pnrc) + " € + " + _toDisplayString(row.pmrc) + " €/mois ", 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_OTableColumn, {
                            field: "row.uniqid",
                            label: "UniqId"
                          }, {
                            default: _withCtx(({ row }: { row: Row }) => [
                              _createTextVNode(_toDisplayString(row.uniqid), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_OTableColumn, { label: "Commande" }, {
                            default: _withCtx(({ row }: { row: Row }) => [
                              _createVNode(_component_OButton, {
                                label: "Commander",
                                onClick: _withModifiers(($event: any) => (commander(row)), ["prevent"])
                              }, null, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["data"])
                    ]))
                  }), 128))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[22] || (_cache[22] = [
                _createElementVNode("div", { class: "column is-1" }, null, -1),
                _createElementVNode("h2", { class: "column" }, "Sur étude.", -1)
              ])))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})