<template>
  <div>
    <div class="columns">
      <div class="column is-5">
        <OTabs v-model="activeTab">
          <OTabItem value="raw" label="Adresse brute">
            <form @submit.prevent="submit">
              <OField horizontal label="Adresse brute">
                <OAutocomplete
                  v-model="raw"
                  :data="rawData"
                  :loading="isFetching"
                  :debounce-typing="500"
                  field="afnor_address"
                  placeholder="12 rue de la paix 75001 paris"
                  expanded
                  @select="submit"
                  @typing="searchRawData"
                />
              </OField>

              <OField horizontal>
                <OButton label="Submit" native-type="submit" :class="{ 'is-loading': loading }" :disabled="loading" />
              </OField>
            </form>
          </OTabItem>

          <OTabItem value="afnor" label="AFNOR">
            <form @submit.prevent="submit">
              <OField horizontal label="N° de maison">
                <OInput v-model="afnor.housenumber" placeholder="12" />
              </OField>

              <OField horizontal label="Répétition">
                <OInput v-model="afnor.rep" placeholder="bis" />
              </OField>

              <OField horizontal label="Non de rue">
                <OInput v-model="afnor.streetname" placeholder="rue de la paix" />
              </OField>

              <OField horizontal label="Ville">
                <OInput v-model="afnor.cityname" placeholder="Paris" />
              </OField>

              <OField horizontal label="Code postal">
                <OInput v-model="afnor.postcode" placeholder="75016" />
              </OField>

              <OField horizontal label="Code INSEE">
                <OInput v-model="afnor.citycode" placeholder="75116" />
              </OField>

              <OField horizontal>
                <OButton label="Submit" native-type="submit" :class="{ 'is-loading': loading }" :disabled="loading" />
              </OField>
            </form>
          </OTabItem>

          <OTabItem value="siret" label="SIRET">
            <form @submit.prevent="submit">
              <OField horizontal label="Siret">
                <OInput v-model="siret" placeholder="441 938 222 00037" />
              </OField>

              <OField horizontal>
                <OButton label="Submit" native-type="submit" :class="{ 'is-loading': loading }" :disabled="loading" />
              </OField>
            </form>
          </OTabItem>

          <OTabItem value="siren" label="SIREN">
            <form @submit.prevent="submit">
              <OField horizontal label="SIREN">
                <OInput v-model="siren" placeholder="441 938 222" />
              </OField>

              <OField horizontal>
                <OButton label="Submit" native-type="submit" :class="{ 'is-loading': loading }" :disabled="loading" />
              </OField>
            </form>
          </OTabItem>

          <OTabItem value="idban" label="IDBAN">
            <form @submit.prevent="submit">
              <OField horizontal label="IDBAN">
                <OInput v-model="idban" placeholder="ADRNIVX_0000000272828236" />
              </OField>

              <OField horizontal>
                <OButton label="Submit" native-type="submit" :class="{ 'is-loading': loading }" :disabled="loading" />
              </OField>
            </form>
          </OTabItem>

          <OTabItem value="interopkey" label="Interop Key">
            <form @submit.prevent="submit">
              <OField horizontal label="Interop Key">
                <OInput v-model="interopkey" placeholder="76540_0850_00053_bis" />
              </OField>

              <OField horizontal>
                <OButton label="Submit" native-type="submit" :class="{ 'is-loading': loading }" :disabled="loading" />
              </OField>
            </form>
          </OTabItem>

          <OTabItem value="coordinates" label="Coordonnées">
            <form @submit.prevent="submit">
              <OField horizontal label="Longitude (est/ouest)">
                <OInput v-model="coordinates.longitude" placeholder="1.09146837396795" />
              </OField>

              <OField horizontal label="Latitude (nord/sud)">
                <OInput v-model="coordinates.latitude" placeholder="49.4737467145085" />
              </OField>

              <OField horizontal>
                <OButton label="Submit" native-type="submit" :class="{ 'is-loading': loading }" :disabled="loading" />
              </OField>
            </form>
          </OTabItem>
        </OTabs>

        <ONotification v-if="error" variant="danger">
          {{ error }}
        </ONotification>

        <ul v-if="'eligibility' in result">
          <li>Eligibilité : {{ result.eligibility }}</li>

          <li>
            Données techniques
            <ul>
              <li>Adresse AFNOR : {{ result.technical_data.afnor_address }}</li>

              <li>Latitude : {{ result.technical_data.latitude }}</li>

              <li>Longitude : {{ result.technical_data.longitude }}</li>

              <li>IDBAN : {{ result.technical_data.idban }}</li>

              <li>Interop Key : {{ result.technical_data.interop_key }}</li>

              <template v-if="result.eligibility">
                <li>Distance : {{ result.technical_data.distance }}</li>

                <li>Pricing : {{ result.technical_data.pricing }}</li>

                <li>Accuracy : {{ result.technical_data.accuracy }}</li>

                <li>Zone name : {{ result.technical_data.zone_name }}</li>
              </template>
            </ul>
          </li>
        </ul>
      </div>

      <div class="column is-7">
        <MyMap
          v-if="'eligibility' in result"
          class="map-size"
          :zoom="12"
          :center="{
            lat: result.technical_data.latitude,
            lng: result.technical_data.longitude,
          }"
        >
          <LGeoJson
            :geojson="{
              type: 'Point',
              coordinates: [result.technical_data.longitude, result.technical_data.latitude],
            }"
          >
            <LTooltip>{{ result.technical_data.afnor_address }}</LTooltip>
          </LGeoJson>

          <LGeoJson
            v-if="activeTab === 'coordinates' && coordinates.longitude && coordinates.latitude"
            :geojson="{
              type: 'Point',
              coordinates: [coordinates.longitude, coordinates.latitude],
            }"
          >
            <LTooltip>Recherche</LTooltip>
          </LGeoJson>
        </MyMap>
      </div>
    </div>

    <template v-if="'eligibility' in result">
      <div v-if="result.eligibility" class="columns">
        <div class="column is-12">
          <h5>Filtres</h5>

          <ul>
            <li v-if="allGtrs.length > 1">
              GTR :
              <OCheckbox v-for="g of allGtrs" :key="g" v-model="gtr" :native-value="g">
                {{ g }}
              </OCheckbox>
            </li>

            <li v-if="allGuaranteedBandwidths.length > 1">
              BP Garantie :
              <OCheckbox v-for="g of allGuaranteedBandwidths" :key="g" v-model="guaranteedBandwidth" :native-value="g">
                {{ g }}
              </OCheckbox>
            </li>
          </ul>

          <div v-for="(pricelist, idx) of filteredPricelist" :key="idx">
            <h5>Prix pour : {{ pricelist.pricelist_name }}</h5>

            <OTable :data="pricelist.offers" striped narrowed>
              <OTableColumn v-slot="{ row }: { row: Row }" field="row.offer_name" label="Offre" sortable>
                {{ row.offer_name }}
              </OTableColumn>

              <OTableColumn v-slot="{ row }: { row: Row }" field="row.technology" label="Techno" sortable>
                {{ row.technology }}
              </OTableColumn>

              <OTableColumn
                v-slot="{ row }: { row: Row }"
                field="row.guaranteed_bandwidth"
                label="BP Garantie"
                sortable
              >
                {{ row.guaranteed_bandwidth }}
              </OTableColumn>

              <OTableColumn v-slot="{ row }: { row: Row }" field="row.maximum_bandwidth" label="BP Max" sortable>
                {{ row.maximum_bandwidth }}
              </OTableColumn>

              <OTableColumn v-slot="{ row }: { row: Row }" field="row.duration_commitment" label="Engagement" sortable>
                {{ row.duration_commitment }} mois
              </OTableColumn>

              <OTableColumn
                v-slot="{ row }: { row: Row }"
                field="row.estimated_commissioning_delay"
                label="Livraison"
                sortable
              >
                {{ row.estimated_commissioning_delay }} mois
              </OTableColumn>

              <OTableColumn v-slot="{ row }: { row: Row }" field="row.gtd" label="Dispo" sortable>
                {{ row.gtd }}%
              </OTableColumn>

              <template v-if="false">
                <!--
                  TODO: https://git.ovea.com/api-sig/admin/-/issues/17
                  <o-table-column
                  v-slot="{ row }: { row: Row }"
                  field="row.gti"
                  label="GTI"
                  sortable
                  >
                  {{ row.gti }}
                  </o-table-column>
                -->
              </template>

              <OTableColumn v-slot="{ row }: { row: Row }" field="row.gtr" label="GTR" sortable>
                {{ row.gtr }}
              </OTableColumn>

              <OTableColumn v-slot="{ row }: { row: Row }" field="row.mrc" label="Prix Revendeur" sortable>
                <b>FAS {{ row.nrc }}&nbsp;€</b>
                +
                <b>{{ row.mrc }}&nbsp;€/mois</b>
              </OTableColumn>

              <OTableColumn v-slot="{ row }: { row: Row }" field="row.pmrc" label="Prix Public" sortable>
                FAS {{ row.pnrc }}&nbsp;€ + {{ row.pmrc }}&nbsp;€/mois
              </OTableColumn>

              <OTableColumn v-slot="{ row }: { row: Row }" field="row.uniqid" label="UniqId">
                {{ row.uniqid }}
              </OTableColumn>

              <OTableColumn v-slot="{ row }: { row: Row }" label="Commande">
                <OButton label="Commander" @click.prevent="commander(row)" />
              </OTableColumn>
            </OTable>
          </div>
        </div>
      </div>

      <div v-else class="columns">
        <div class="column is-1"></div>

        <h2 class="column">Sur étude.</h2>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { publicClient } from '@api-sig/client-public';
import type { Components, Paths } from '@api-sig/openapi-public';
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import MyMap from '@/components/Map.vue';

export type Row = Components.Schemas.Offer;

defineOptions({ name: 'Recherche' });

// eslint-disable-next-line @typescript-eslint/no-var-requires, unicorn/prefer-module, @typescript-eslint/no-unsafe-assignment
const { LTooltip, LGeoJson } = require('@vue-leaflet/vue-leaflet');

const router = useRouter();

const activeTab = ref('raw');
const loading = ref(false);
const siret = ref('');
const siren = ref('');
const idban = ref('');
const interopkey = ref('');
const raw = ref('');
const error = ref('');
const isFetching = ref(false);
const gtr = ref<string[]>([]);
const guaranteedBandwidth = ref<string[]>([]);

const rawData = ref<Components.Schemas.PublicSearchItem[]>([]);

const coordinates = reactive<Paths.EligCoordinates.QueryParameters>({
  latitude: '',
  longitude: '',
});

const afnor = reactive<Paths.EligAfnor.QueryParameters>({
  housenumber: '',
  rep: '',
  streetname: '',
  cityname: '',
  postcode: '',
  citycode: '',
});

const result = ref<Components.Schemas.EligibilityAnswer | Record<never, never>>({});

/*
 * Ne sort que les offres qui correspondent au filtre,
 * puis les pricelists non vides
 */
const filterByOffer = (
  pricelist: Components.Schemas.Pricelist[],
  filter: (o: Components.Schemas.Offer) => boolean
): Components.Schemas.Pricelist[] =>
  pricelist
    .map((p) => ({
      pricelist_name: p.pricelist_name,
      offers: p.offers.filter((o) => filter(o)),
    }))
    .filter((p) => p.offers.length > 0);

const filteredPricelist = computed<Components.Schemas.Pricelist[]>(() => {
  if (!('pricelists' in result.value)) {
    return [];
  }

  let returnValue = result.value.pricelists;

  returnValue = filterByOffer(returnValue, (o) => gtr.value.includes(o.gtr));

  returnValue = filterByOffer(returnValue, (o) => guaranteedBandwidth.value.includes(o.guaranteed_bandwidth));

  return returnValue;
});

const allGtrs = computed<string[]>(() => {
  if (!('pricelists' in result.value)) {
    return [];
  }

  return [...new Set(result.value.pricelists.flatMap((p) => p.offers.map((o) => o.gtr)))];
});

const allGuaranteedBandwidths = computed<string[]>(() => {
  if (!('pricelists' in result.value)) {
    return [];
  }

  return [...new Set(result.value.pricelists.flatMap((p) => p.offers.map((o) => o.guaranteed_bandwidth)))];
});

const submitCorrect = async () => {
  const client = await publicClient;

  switch (activeTab.value) {
    case 'raw': {
      return client.eligRaw({ address: raw.value });
    }

    case 'afnor': {
      const clone: Paths.EligAfnor.QueryParameters = { ...afnor };

      let k: keyof typeof clone;

      for (k in clone) {
        if (clone[k] === '') {
          delete clone[k];
        }
      }

      return client.eligAfnor(clone);
    }

    case 'siret': {
      return client.eligSiret({ siret: siret.value.replaceAll(/\s+/g, '') });
    }

    case 'siren': {
      return client.eligSiren({ siren: siren.value.replaceAll(/\s+/g, '') });
    }

    case 'idban': {
      return client.eligIdban({ idban: idban.value });
    }

    case 'interopkey': {
      return client.eligInteropkey({ interop_key: interopkey.value });
    }

    case 'coordinates': {
      return client.eligCoordinates(coordinates);
    }

    default: {
      throw new Error('should not happen');
    }
  }
};

const submit = async () => {
  try {
    error.value = '';

    loading.value = true;

    const resp = await submitCorrect();

    result.value = resp.data;
    gtr.value = allGtrs.value;
    guaranteedBandwidth.value = allGuaranteedBandwidths.value;
  } catch (error_) {
    result.value = {};

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    error.value = error_ instanceof Error ? error_.message : `unknown error: ${error_}`;
  } finally {
    loading.value = false;
  }
};

const searchRawData = async (address: string) => {
  isFetching.value = true;

  const client = await publicClient;

  try {
    const resp = await client.publicAddressSearch({ address });

    rawData.value = resp.data;
    isFetching.value = false;
  } catch {
    // rien
  }
};

const commander = async (offer: Components.Schemas.Offer) => {
  // TODO: utiliser une fenêtre modale
  // eslint-disable-next-line no-alert, no-restricted-globals
  if (confirm(`Commander l'offre ${offer.offer_name} ?`)) {
    await router.push({
      name: 'NewCommande',
      query: { offerId: offer.uniqid },
    });
    // go there
  }
};
</script>

<style lang="scss" scoped>
.notification {
  margin-top: 1em;
  padding: 0.8em 1em;
}

ul {
  padding-left: 1.5rem;
}

::v-deep(.tabs) {
  border-bottom: 1px solid #dbdbdb;

  button.button {
    padding: 0.4rem;
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    height: 1.6rem;
  }
}

::v-deep(th.is-sortable) {
  cursor: ns-resize;
}

// On a besoin d'un peu plus de place.
::v-deep(.field.is-horizontal .field-label) {
  flex-grow: 2;
}

::v-deep(input[type='checkbox'] + .field-label) {
  margin-left: 0.3rem;
}

// par défaut le dropdown est caché, et le v-if suffit pas
::v-deep(.dropdown-menu) {
  display: block;
}

.map-size {
  width: 100%;
  height: 100%;
}
</style>
