import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

import 'leaflet/dist/leaflet.css';

import type { FitBoundsOptions, LatLngBoundsExpression, LatLngLiteral } from 'leaflet';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Map',
  props: {
    bounds: { default: () => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const returnValue = {} as LatLngBoundsExpression;
    return returnValue;
  } },
    center: { default: () => ({ lat: 43.610_228_141_786_43, lng: 3.916_625_976_562_500_4 }) },
    zoom: { default: 10 }
  },
  emits: ["update:zoom", "update:center", "update:bounds"],
  setup(__props: any, { expose: __expose }) {





// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const {
  LMap,
  LTileLayer,
  LControlLayers,
  // eslint-disable-next-line @typescript-eslint/no-var-requires, unicorn/prefer-module
} = require('@vue-leaflet/vue-leaflet');

const map = ref<typeof LMap>();

const layers = {
  OpenStreetMap: {
    visible: true,
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',

    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  },

  OpenTopoMap: {
    visible: false,
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',

    attribution:
      'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, '
      + '<a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; '
      + '<a href="https://opentopomap.org">OpenTopoMap</a> '
      + '(<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
  },
};

const fitBounds = (bounds: LatLngBoundsExpression, options: FitBoundsOptions): void => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  map.value.leafletObject.fitBounds(bounds, options);
};

__expose({ fitBounds });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(LMap), {
      ref_key: "map",
      ref: map,
      zoom: _ctx.zoom,
      center: _ctx.center,
      bounds: _ctx.bounds,
      "onUpdate:zoom": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:zoom', $event))),
      "onUpdate:center": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:center', $event))),
      "onUpdate:bounds": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:bounds', $event)))
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(LControlLayers)),
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(layers, (layer, name) => {
          return _createVNode(_unref(LTileLayer), {
            key: name,
            name: name,
            visible: layer.visible,
            url: layer.url,
            attribution: layer.attribution,
            "layer-type": "base"
          }, null, 8, ["name", "visible", "url", "attribution"])
        }), 64)),
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["zoom", "center", "bounds"])
  ]))
}
}

})