import { defineComponent as _defineComponent } from 'vue'
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { useStore } from '@/store';


export default /*@__PURE__*/_defineComponent({
  ...{ name: 'Logout' },
  __name: 'Logout',
  setup(__props) {



const store = useStore();
const router = useRouter();

onMounted(async () => {
  await store.dispatch('auth/logout');
  await router.push({ name: 'Login' });
});

return () => {}
}

})