import ActivityBasedTimer from '@api-sig/activity-based-timer';
import { publicClient } from '@api-sig/client-public';
import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

import isNewerVersionAvailable from '@/services/versioning';
import Commande from '@/views/Commande.vue';
import Compte from '@/views/Compte.vue';
import Historique from '@/views/Historique.vue';
import Login from '@/views/Login.vue';
import Logout from '@/views/Logout.vue';
import NewCommande from '@/views/NewCommande.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import Recherche from '@/views/Recherche.vue';

import { store } from '../store';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Recherche',
    component: Recherche,
  },
  {
    path: '/historique',
    name: 'Historique',
    component: Historique,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/compte',
    name: 'Compte',
    component: Compte,
  },
  {
    path: '/password',
    name: 'Password',
    component: PasswordReset,
    props: (route) => ({ token: route.query.token }),
  },
  {
    path: '/commande/new',
    name: 'NewCommande',
    component: NewCommande,
    props: (route) => ({ offerId: route.query.offerId }),
  },
  {
    path: '/commande',
    name: 'Commande',
    component: Commande,
    props: (route) => ({ orderId: route.query.orderId }),
  },
];

const router = createRouter({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes,
});

const versioningTimer = ActivityBasedTimer();

versioningTimer.setInterval({
  async callback() {
    const newVersionAvailable = await isNewerVersionAvailable();

    if (!newVersionAvailable) {
      return;
    }

    await store.dispatch('version/setNewVersion', true);
  },

  // Normal interval is once every 10 minutes
  interval: 1000 * 60 * 10,
  // Forced interval is once per day
  forcedInterval: 1000 * 60 * 60 * 24,
});

router.beforeEach(async (_to, _from, next) => {
  await versioningTimer.runTimersCheck();
  next();
});

router.beforeEach(async (to, _from, next) => {
  const publicPages = ['/login', '/password'];
  const authRequired = !publicPages.includes(to.path);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const loggedIn: boolean = store.getters['auth/loggedIn'];

  /*
   * trying to access a restricted page + not logged in
   * redirect to login page
   */
  if (!authRequired) {
    next();
  } else if (loggedIn) {
    try {
      // Si on est là, on check que le token est toujours bon, et sinon, paf.
      const client = await publicClient;
      await client.authToken();

      next();
    } catch {
      await store.dispatch('auth/logout');
      next('/login');
    }
  } else {
    next('/login');
  }
});

export default router;
