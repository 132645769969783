<template>
  <NavBar v-if="loggedIn" />

  <main class="content">
    <RouterView />
  </main>

  <footer class="footer">
    En cas de problèmes, merci d'envoyer un email à
    <a href="mailto:support@ovea.com?subject=Problème%20API%20d'éligibilité">support@ovea.com</a>
  </footer>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import NavBar from './components/NavBar.vue';
import { useStore } from './store';

defineOptions({ name: 'App' });

const store = useStore();

const loggedIn = computed(() => store.getters['auth/loggedIn']);
</script>

<style lang="scss">
html,
body {
  height: 100%;
}

body {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-rows: auto;
  grid-template-areas: 'app';
}

app {
  grid-area: app;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto;
  grid-template-rows: 40px 1fr auto;
  grid-template-areas:
    'menu'
    'main'
    'footer';
}

nav {
  grid-area: menu;
}

main {
  grid-area: main;
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: auto;
  grid-gap: 20px;
  padding: 0.5rem 0.5rem 0 0.5rem;
}

app > footer.footer {
  grid-area: footer;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  text-align: center;
}

// Aligner le label au milieu du champ qu'il décrit.
.field.is-horizontal .field-label {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

// On a toujours un : après un label.
label:not(.checkbox):not(.file)::after {
  // nbsp + :
  content: '\00A0:';
}

.leaflet-container label::after {
  content: '' !important;
}
</style>
