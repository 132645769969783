import './assets/bulma.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleLeft,
  faAngleRight,
  faArrowUp,
  faBackward,
  faBuilding,
  faCalendar,
  faCheck,
  faCircleNotch,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFlag,
  faForward,
  faMapPin,
  faMobile,
  faPhone,
  faSyncAlt,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import '@oruga-ui/oruga-next/dist/oruga-full-vars.css';
import Oruga from '@oruga-ui/oruga-next';
import { bulmaConfig } from '@ovea-dev/common-bulma-ovea';
import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import { key, store } from './store';

library.add(
  faAngleLeft,
  faAngleRight,
  faArrowUp,
  faBackward,
  faBuilding,
  faCalendar,
  faCheck,
  faCircleNotch,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFlag,
  faForward,
  faMapPin,
  faMobile,
  faPhone,
  faSyncAlt,
  faWarehouse
);

const app = createApp(App);

const toDMS = (dd: number): string => {
  const deg = Math.round(dd); // truncate dd to get degrees
  const frac = Math.abs(dd - deg); // get fractional part
  const min = Math.round(frac * 60); // multiply fraction by 60 and truncate
  const sec = Math.round((frac * 3600 - min * 60) * 10) / 10;

  return `${deg}°${min}'${sec}"`;
};

app.config.globalProperties.$latitude = (lat: number | undefined): string =>
  lat === undefined ? '' : `${toDMS(lat)}${lat > 0 ? 'N' : 'S'}`;

app.config.globalProperties.$longitude = (lon: number | undefined): string =>
  lon === undefined ? '' : `${toDMS(lon)}${lon > 0 ? 'E' : 'W'}`;

app
  .use(store, key)
  .use(router)
  .use(Oruga, {
    iconPack: 'fa',
    iconComponent: 'font-awesome-icon',
    ...bulmaConfig,
  })
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('app');
