<!-- eslint-disable vue/no-root-v-if -->
<template>
  <template v-if="order.id">
    <h1 class="title">Confirmation de la Commande</h1>

    <div class="columns">
      <div v-if="offer.technical_data" class="column is-4">
        <h3>Offre</h3>

        <p>
          Cette commande concerne l'adresse :
          <b>{{ offer.technical_data.afnor_address }}</b
          >, localisée aux coordonnées {{ $latitude(offer.technical_data?.latitude) }} /
          {{ $longitude(offer.technical_data?.longitude) }}.
        </p>

        <p>Cette commande comporte les informations suivantes :</p>

        <ul>
          <li>Libellé : {{ offer.offer.offer_name }}</li>

          <li>Engagement : {{ offer.offer.duration_commitment }} mois</li>

          <li>Setup : {{ offer.offer.nrc }}&nbsp;€</li>

          <li>Récurrent mensuel : {{ offer.offer.mrc }}&nbsp;€/mois</li>
        </ul>
      </div>

      <div class="column is-4">
        <h3>Contacts</h3>

        <dl>
          <template v-for="(contact, key) of orderContacts" :key="key">
            <template v-if="contact">
              <dt>{{ key }}</dt>

              <dd>
                <template v-if="contact.type === 'person'">
                  {{ contact.firstname }} {{ contact.lastname }}
                  <template v-if="contact.company_name">({{ contact.company_name }})</template>
                </template>

                <template v-else>
                  {{ contact.company_name }}
                  <template v-if="contact.firstname || contact.lastname">
                    ({{ contact.firstname }} {{ contact.lastname }})
                  </template>
                </template>

                <br />

                {{ contact.representative_full_name }}<br />
                {{ contact.address_line1 }},
                <template v-if="contact.address_line2">{{ contact.address_line2 }}, </template>

                <template v-if="contact.address_line3">{{ contact.address_line3 }}, </template>

                {{ contact.postcode }} {{ contact.city }} {{ contact.country }}<br />

                <template v-if="contact.representative_email">
                  <a :href="'mailto:' + contact.representative_email">
                    <FontAwesomeIcon icon="envelope" />
                    {{ contact.representative_email }}
                  </a>

                  <br />
                </template>

                <template v-if="contact.representative_mobile_phone">
                  <a :href="'tel:' + contact.representative_mobile_phone">
                    <FontAwesomeIcon icon="mobile" />
                    {{ contact.representative_mobile_phone }}
                  </a>
                </template>

                <template v-if="contact.representative_office_phone">
                  <a :href="'tel:' + contact.representative_office_phone">
                    <FontAwesomeIcon icon="phone" />
                    {{ contact.representative_office_phone }}
                  </a>
                </template>

                <br />
              </dd>
            </template>
          </template>
        </dl>
      </div>

      <div class="column is-4">
        <h3>Informations complémentaires</h3>

        <dl>
          <dt>Nom du client</dt>

          <dd>{{ order.customer_name }}</dd>

          <template v-if="order.pe_configuration">
            <dt>Configuration PE</dt>

            <dd>{{ order.pe_configuration }}</dd>
          </template>

          <template v-if="order.extra_information">
            <dt>Informations complémentaires</dt>

            <dd>{{ order.extra_information }}</dd>
          </template>

          <template v-if="order.bitstream_trunk">
            <dt>Porte de collecte</dt>

            <dd>{{ order.bitstream_trunk }}</dd>
          </template>

          <template v-if="order.premises_availability_date">
            <dt>Disponibilité sur site</dt>

            <dd>
              {{ new Date(order.premises_availability_date).toISOString().slice(0, 10) }}
            </dd>
          </template>
        </dl>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { publicClient } from '@api-sig/client-public';
import type { Components } from '@api-sig/openapi-public';
import { onMounted, reactive, ref } from 'vue';

const props = defineProps<{ orderId: string }>();

const order = ref({} as Components.Schemas.Order);
const offer = ref({} as Components.Schemas.FullOffer);
const orderContacts = reactive<Record<string, Components.Schemas.Contact | undefined>>({});

const getOrder = async () => {
  const client = await publicClient;

  const resp = await client.orderList({ id: props.orderId });

  if (resp.data !== undefined) {
    order.value = resp.data[0];

    orderContacts['Contact sur site'] = order.value.installation_site_contact;

    orderContacts['Contact technique'] = order.value.technical_contact;
    orderContacts['Contact facturation'] = order.value.billing_contact;

    const resp2 = await client.offerList({
      uniqid: order.value.offers[0].uniqid,
    });

    if (resp2.data !== undefined) {
      offer.value = resp2.data;
    }
  }
};

onMounted(getOrder);
</script>
