<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title">Compte</h4>
    </div>

    <div class="card-content">
      <form class="card" @submit.prevent="changePassword">
        <header class="card-header">
          <h5 class="card-header-title">Modification du mot de passe</h5>
        </header>

        <section class="card-content">
          <small>La modification du mot de passe invalidera vos jetons d'authentification pour l'API.</small>

          <OField
            label="Ancien Mot de passe"
            :variant="variant.old_password"
            :message="message.old_password"
            data-test="old_password"
          >
            <OInput v-model="oldPassword" type="password" expanded password-reveal />
          </OField>

          <OField label="Mot de passe" :variant="variant.password" :message="message.password" data-test="password">
            <OInput v-model="password" type="password" expanded password-reveal />
          </OField>

          <OField
            label="Confirmation"
            :variant="variant.confirm"
            :message="message.confirm"
            data-test="password_confirm"
          >
            <OInput v-model="passwordConfirm" type="password" expanded password-reveal />
          </OField>

          <ONotification v-if="message.notif" :variant="variant.notif">
            {{ message.notif }}
          </ONotification>
        </section>

        <footer class="card-footer">
          <OButton native-type="submit" variant="primary" data-test="submit">Save changes</OButton>
        </footer>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { publicClient } from '@api-sig/client-public';
import ApiError from '@api-sig/vue-api-error';
import { computed, reactive, ref } from 'vue';

import { useStore } from '@/store';

defineOptions({ name: 'Compte' });

const store = useStore();

const oldPassword = ref('');

const password = ref('');

const passwordConfirm = ref('');

const message = reactive({
  old_password: '',
  password: '',
  confirm: '',
  notif: '',
});

const variant = reactive({
  old_password: '',
  password: '',
  confirm: '',
  notif: '',
});

const user = computed(() => store.getters['auth/user']);

const changePassword = async () => {
  message.old_password = '';
  message.password = '';
  message.confirm = '';
  variant.old_password = '';
  variant.password = '';
  variant.confirm = '';

  if (oldPassword.value === '') {
    message.old_password = "l'ancien mot de passe ne peut être vide";
    variant.old_password = 'danger';
  }

  if (password.value === '') {
    message.password = 'le mot de passe ne peut être vide';
    variant.password = 'danger';
  }

  if (password.value !== passwordConfirm.value) {
    message.confirm = 'le mot de passe et la confirmation ne correspondent pas';

    variant.confirm = 'danger';
  }

  if (variant.old_password || variant.password || variant.confirm) {
    return;
  }

  const client = await publicClient;

  try {
    await client.authNewPassword(undefined, {
      old_password: oldPassword.value,
      password: password.value,
    });

    variant.notif = 'success';
    message.notif = 'Mot de passe modifié';

    await store.dispatch('auth/login', {
      email: user.value.email,
      password: password.value,
    });

    oldPassword.value = '';
    password.value = '';
    passwordConfirm.value = '';
  } catch (error) {
    variant.notif = 'danger';

    message.notif = new ApiError(error).message;
  }
};
</script>

<style lang="scss" scoped>
.card-footer {
  padding: 1.5rem;
}
</style>
