import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

import { publicClient } from '@api-sig/client-public';
import type { Paths } from '@api-sig/openapi-public';
import ApiError from '@api-sig/vue-api-error';
import { computed, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useStore } from '@/store';


export default /*@__PURE__*/_defineComponent({
  ...{ name: 'Login' },
  __name: 'Login',
  setup(__props) {



const store = useStore();
const router = useRouter();

const user = reactive<Paths.AuthAuth.QueryParameters>({
  email: '',
  password: '',
});

const loading = ref(false);

const message = ref('');

const loggedIn = computed(() => store.getters['auth/loggedIn']);

// @Auth.Action login!: Actions['auth/login'];

const handleLogin = async () => {
  loading.value = true;

  if (user.email && user.password) {
    try {
      message.value = '';

      await store.dispatch('auth/login', user);

      loading.value = false;

      await router.push({ name: 'Recherche' });
    } catch (error) {
      loading.value = false;

      message.value = new ApiError(error).message;
    }
  } else {
    message.value = 'Email or password missing';
    loading.value = false;
  }
};

const resetPassword = async () => {
  if (user.email) {
    const client = await publicClient;
    await client.authRecoverSend({ email: user.email });
    message.value = 'Email envoyé';
  } else {
    message.value = 'Email missing';
  }
};

onMounted(async () => {
  if (loggedIn.value) {
    await router.push({ name: 'Recherche' });
  }
});

return (_ctx: any,_cache: any) => {
  const _component_OInput = _resolveComponent("OInput")!
  const _component_OField = _resolveComponent("OField")!
  const _component_OButton = _resolveComponent("OButton")!
  const _component_OLoading = _resolveComponent("OLoading")!
  const _component_ONotification = _resolveComponent("ONotification")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("form", {
      name: "form",
      onSubmit: _withModifiers(handleLogin, ["prevent"])
    }, [
      _createVNode(_component_OField, {
        horizontal: "",
        label: "Email"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_OInput, {
            modelValue: user.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((user.email) = $event)),
            type: "email",
            expanded: ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_OField, {
        horizontal: "",
        label: "Password"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_OInput, {
            modelValue: user.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((user.password) = $event)),
            type: "password",
            expanded: ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_OField, { horizontal: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_OButton, {
            label: "Login",
            "native-type": "submit",
            onClick: handleLogin
          }),
          _createVNode(_component_OButton, {
            label: "Mot de passe oublié",
            "native-type": "submit",
            onClick: _withModifiers(resetPassword, ["prevent"])
          })
        ]),
        _: 1
      }),
      _createVNode(_component_OLoading, {
        "full-page": false,
        active: loading.value
      }, null, 8, ["active"]),
      (message.value)
        ? (_openBlock(), _createBlock(_component_ONotification, {
            key: 0,
            variant: "danger"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(message.value), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], 32)
  ]))
}
}

})